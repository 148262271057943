import React, {
  forwardRef,
  useState,
  useEffect,
  useCallback,
  useImperativeHandle,
  useMemo,
} from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormGroup,
  InputLabel,
  MenuItem,
  Paper,
  SelectChangeEvent,
  Stack,
  TextField,
} from "@mui/material";
import { Colors } from "@template/style";
import formatDateToString from "@utils/DateFormat";
import {
  StateFormType,
  getInfoFromTemplate,
  getImageArray,
  makeNewHtml,
  insertReportLocal,
} from "@utils/template/loadingReport";
import CancelBtnBadge from "components/atoms/CancelBtnBadge";
import DatePickerCustom from "components/atoms/DatePickerCustom";
import SelectLabel from "components/atoms/SelectLabel";
import LabelRequired from "components/atoms/LabelRequired";
import { cloneDeep, debounce, isEqual } from "lodash";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import { Validation } from "@validation";
import ModalController from "@shared-components/modal/ModalController";
import messages from "config/messages";
import { uploadImageToS3 } from "@utils/template";
import AccordionSection from "components/molecules/AccordionSection";
import CustomImagePicker from "./CustomImagePicker";
import { getM1, getM5, getM21, getM22, getModelNameMaster } from "@api/master";
import { createFormUpload } from "@utils/index";
import { uploadImageForTemplate } from "@api/template";

const originData: StateFormType = {
  risky_id: "",
  loading_date: formatDateToString(new Date(), "YMD"),
  warehouse: null,
  warehouse_other: "",
  load_by: null,
  invoice_no: "",
  booking_no: "",
  commodity: null,
  commodity_other: "",
  container_type: null,
  container_no: "",
  seal_no: "",
  remark: "",
  model_and_qty: [],
  total_qty: 0,
  container_condition_photo: [],
  other_document_photo: [],
  loading_situation_photo: [],
};

const maxNumberImageDefault: number = 18;

interface IFormError {
  loading_date: string;
  warehouse: string;
  load_by: string;
  commodity: string;
  container_no: string;
  model_and_qty: string[];
  [key: string]: string[] | string;
}

interface ILoadingReport {
  infoToEdit?: any;
  htmlString: string;
  setHtmlString: Function;
  open: boolean;
  step: number;
}

const REPORTER_STEP = 0;

const LoadingReport = (
  { infoToEdit, htmlString, setHtmlString, open, step }: ILoadingReport,
  ref: React.Ref<unknown>,
) => {
  const [stateForm, setStateForm] = useState<StateFormType>(
    cloneDeep(originData),
  );
  const [originStateForm, setOriginStateForm] = useState<StateFormType>(
    cloneDeep(originData),
  );
  const [completeGetData, setCompleteGetData] = useState<boolean>(false);
  const [searchJanCodeParams, setSearchJanCodeParams] = useState<Array<string>>(
    [],
  );
  const [qtyErrorIndex, setQtyErrorIndex] = useState<number[]>([]);
  const [listReportItem, setListReportItem] = useState<{
    listWarehouse: Array<any>;
    listLoadBy: Array<any>;
    listCommodity: Array<any>;
    listContainerType: Array<any>;
  }>({
    listWarehouse: [],
    listLoadBy: [],
    listCommodity: [],
    listContainerType: [],
  });
  const [formError, setFormError] = useState<IFormError>({
    loading_date: "",
    warehouse: "",
    load_by: "",
    container_no: "",
    commodity: "",
    model_and_qty: [],
  });
  const [countUploadImage, setCountUploadImage] = useState<number>(0);
  const [listUploadedImage, setListUploadedImage] = useState<any[]>([]);

  useImperativeHandle(ref, () => ({
    getStateForm: () => {
      // プレビュー用の値を削除
      let cloneStateForm = cloneDeep(stateForm);
      cloneStateForm.container_condition_photo.map((item: any) => {
        delete item.base64;
      });
      cloneStateForm.other_document_photo.map((item: any) => {
        delete item.base64;
      });
      cloneStateForm.loading_situation_photo.map((item: any) => {
        delete item.base64;
      });
      return cloneStateForm;
    },
    getOriginStateForm: () => originStateForm,
    onSubmit: onSubmit,
    disabledSubmit: () => countUploadImage !== listUploadedImage.length,
  }));

  // マスタデータ取得 =====================================
  const addWhiteSpace = (data: Array<any>) => {
    if (data.length == 0) {
      return data;
    }
    data.splice(0, 0, undefined);
    return data;
  };

  const getData = async () => {
    try {
      LoadingOverlayController.show();

      let listReportItemClone = cloneDeep(listReportItem);

      const resWarehouse = await getM1();
      if (resWarehouse?.data) {
        listReportItemClone.listWarehouse = addWhiteSpace(resWarehouse.data);
      }
      const resLoadBy = await getM21();
      if (resLoadBy?.data) {
        listReportItemClone.listLoadBy = addWhiteSpace(resLoadBy.data);
      }
      const resListCommodity = await getM5();
      if (resListCommodity?.data) {
        listReportItemClone.listCommodity = addWhiteSpace(
          resListCommodity.data,
        );
      }
      const resListContainer = await getM22();
      if (resListContainer?.data) {
        listReportItemClone.listContainerType = addWhiteSpace(
          resListContainer.data,
        );
      }
      setListReportItem(listReportItemClone);
      setCompleteGetData(true);
    } catch (err) {
      console.log(err);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  useEffect(() => {
    getData();

    return () => {
      setListReportItem({
        listWarehouse: [],
        listLoadBy: [],
        listCommodity: [],
        listContainerType: [],
      });
      setCompleteGetData(false);
    };
  }, []);

  useEffect(() => {
    if (open && completeGetData) {
      if (typeof infoToEdit?.remark === "string") {
        // 別画面からの遷移
        let tmpInfoToEdit = cloneDeep(infoToEdit);
        setStateForm(tmpInfoToEdit);
        setOriginStateForm(infoToEdit);
      } else {
        setStateForm({
          ...cloneDeep(originData),
          warehouse: setDefaultValue("warehouse", listReportItem.listWarehouse),
          load_by: setDefaultValue("load_by", listReportItem.listLoadBy),
          commodity: setDefaultValue("commodity", listReportItem.listCommodity),
          container_type: setDefaultValue(
            "container_type",
            listReportItem.listContainerType,
          ),
          risky_id: infoToEdit?.risky_id ?? "",
        });
        setOriginStateForm({
          ...cloneDeep(originData),
          risky_id: infoToEdit?.risky_id ?? "",
        });
      }
    }
  }, [open, completeGetData, listReportItem]);

  // 初期値の設定
  const setDefaultValue = (
    field: keyof StateFormType,
    listObject: Array<any>,
  ) => {
    const default_data = listObject.find((item) => item?.is_default === true);

    if (default_data) {
      return default_data;
    } else {
      return originData[field];
    }
  };

  // HTML取得 =====================================
  const getHtmlString = useCallback(
    async (state_form: StateFormType, html_String: string) => {
      setHtmlString(""); // 編集完了ボタンを再描画する為、htmlを変更
      const res = getInfoFromTemplate(html_String);

      const container_condition_image_array = await getImageArray(
        state_form.container_condition_photo,
      );
      const other_document_image_array = await getImageArray(
        state_form.other_document_photo,
      );
      const loading_situation_image_array = await getImageArray(
        state_form.loading_situation_photo,
      );

      let newHtml: string = makeNewHtml(
        html_String,
        state_form,
        res.addTableAreaExample,
        res.qtyTotalExample,
        res.insertTableRowExample,
      );

      newHtml = insertReportLocal(
        newHtml,
        state_form,
        res.imageProductExample,
        res.imageConditionContainer,
        res.imageLoadingSituationPhoto,
        res.imageOtherDocument,
      );
      setHtmlString(newHtml);

      if (
        !isEqual(
          container_condition_image_array,
          state_form.container_condition_photo,
        )
      ) {
        setStateForm({
          ...state_form,
          container_condition_photo: container_condition_image_array,
        });
      }
      if (
        !isEqual(other_document_image_array, state_form.other_document_photo)
      ) {
        setStateForm({
          ...state_form,
          other_document_photo: other_document_image_array,
        });
      }
      if (
        !isEqual(
          loading_situation_image_array,
          state_form.loading_situation_photo,
        )
      ) {
        setStateForm({
          ...state_form,
          loading_situation_photo: loading_situation_image_array,
        });
      }
    },
    [],
  );

  const changeForm = useCallback(debounce(getHtmlString, 300), [getHtmlString]);

  useEffect(() => {
    changeForm(stateForm, htmlString);
  }, [stateForm, htmlString]);

  // 入力制御 =====================================
  const onChangeText =
    (field: string) =>
      (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setStateForm({ ...stateForm, [field]: e.target.value });
      };

  function convertFullWidthToHalfWidth(inputString: string) {
    return inputString.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (char) {
      return String.fromCharCode(char.charCodeAt(0) - 65248);
    });
  }

  const onChangeTextUpperCase =
    (field: string) =>
      (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        let halfWidthString = convertFullWidthToHalfWidth(e.target.value);
        let upperCaseString = halfWidthString.replace(
          /[a-zA-Z]/g,
          function (char) {
            return char.toUpperCase();
          },
        );
        setStateForm({ ...stateForm, [field]: upperCaseString });
      };

  const onChangeSelect =
    (field: string) => (list: any[], e: SelectChangeEvent<string>) => {
      const selected = list.find((item) => item && item.SK === e.target.value);
      setStateForm((prev) => ({ ...prev, [field]: selected }));
    };

  const onChangeDate = (field: string) => (value: string | null | Date) => {
    if (!value) {
      value = "";
    } else {
      value = formatDateToString(value, "YMD");
    }

    let mess = "";
    if (value) {
      mess = Validation.validateDate(value, "Loading Date", true);
    }
    setFormError({ ...formError, [field]: mess });
    setStateForm((prev) => ({ ...prev, [field]: value }));
  };

  const onValidateQuantity = () => {
    let mess: any = [];
    const newErrorIndex: number[] = [];
    stateForm["model_and_qty"].forEach(({ qty }, index: number) => {
      const original_mess = Validation.validate({
        type: "number",
        name: "Quantity",
        value: qty.toString(),
      });
      if (original_mess.length > 0) {
        newErrorIndex.push(index);
        mess.push(original_mess);
      }
    });
    setQtyErrorIndex(newErrorIndex);
    mess = [...new Set(mess)];
    setFormError({ ...formError, model_and_qty: mess });
  };

  const uploadImage = (
    imageArrayName: keyof StateFormType,
    imageInfo: Type.ImageInfoType,
  ) => {
    setCountUploadImage((prev) => prev + 1);
    let form = createFormUpload(imageInfo);
    uploadImageForTemplate(form).then((res) => {
      if (res?.link_url_file) {
        setListUploadedImage((prev) => {
          const cloneListUploadImage = cloneDeep(prev);
          cloneListUploadImage.push(res);
          return cloneListUploadImage;
        });
        setStateForm((prev) => {
          const cloneData = cloneDeep(prev);
          const index = prev[imageArrayName].findIndex(
            (value: any) => value.uri === imageInfo.uri,
          );
          if (index >= 0)
            cloneData[imageArrayName][index] = {
              ...imageInfo,
              base64: undefined,
              path_file: res?.path_file,
              path_file_jpg: res?.path_file_jpg ?? "",
              uri: res?.link_url_file,
              uri_jpg: res?.link_url_file_jpg,
              non_Local: true,
            };
          return cloneData;
        });
      }
    });
  };

  const validation = (
    setErrorForm: Function,
    errorForm: any,
    field: keyof StateFormType,
    value: any,
    index?: number,
  ) => {
    let mess: any = "";
    switch (field) {
      case "loading_date":
        if (step > REPORTER_STEP) {
          mess = Validation.validateDate(value, "Loading Date", true);
          setFormError({ ...formError, loading_date: mess });
        }
        break;
      case "warehouse":
        if (step > REPORTER_STEP) {
          mess = Validation.validate({
            type: "text",
            value: value,
            required: true,
            name: "Warehouse",
          });
          setFormError({ ...formError, warehouse: mess });
        }
        break;
      case "warehouse_other":
        mess = Validation.validate({
          type: "text",
          value: value,
          required: false,
          name: "Warehouse (other)",
          max_length: 16
        });
        setFormError({ ...formError, warehouse_other: mess });
        break;
      case "load_by":
        if (step > REPORTER_STEP) {
          mess = Validation.validate({
            type: "text",
            value: value,
            required: true,
            name: "Load By",
          });
          setFormError({ ...formError, load_by: mess });
        }
        break;
      case "commodity":
        if (step > REPORTER_STEP) {
          mess = Validation.validate({
            type: "text",
            value: value,
            required: true,
            name: "Commodity",
          });
          setFormError({ ...formError, commodity: mess });
        }
        break;
      case "commodity_other":
        mess = Validation.validate({
          type: "text",
          value: value,
          required: false,
          name: "commodity (other)",
          max_length: 30
        });
        setFormError({ ...formError, commodity_other: mess });
        break;
      case "container_no":
        mess = Validation.validate({
          type: "text",
          value: value,
          required: true,
          name: "Container No.",
        });
        setFormError({ ...formError, container_no: mess });
        break;
    }
    mess = mess.length > 0 ? mess : "";
    return mess.length == 0;
  };

  const createErrorMessages = (errorObject: any) => {
    let list: any[] = [];
    Object.keys(errorObject).forEach((value: any) => {
      const key: keyof typeof errorObject = value;
      list = list.concat(errorObject[key] || []);
    });
    const result = [...new Set(list)];
    return result;
  };

  const ErrorMessage = useMemo(() => {
    let list: any[] = createErrorMessages(formError);
    const result: string[] = [...new Set(list)];
    return result;
  }, [formError]);

  const renderMenuItem = useCallback(
    (list: Array<any>, value: string, name: string) => {
      return list
        ? list.map((item, index) => (
          <MenuItem value={item ? item[value] : ""} key={index}>
            {item ? (
              item[value] !== "0" ? (
                <span className="IgnoreExtractRuleTarget">{item[name]}</span>
              ) : (
                <em>{item[name]}</em>
              )
            ) : (
              <em>未選択</em>
            )}
          </MenuItem>
        ))
        : null;
    },
    [],
  );

  /**  アコーディオンセクション① Models */
  const renderItem1 = () => {
    const inputDefaultItems = {
      model: "",
      qty: 0,
    };

    const isNumber = (value: number) => Number.isFinite(value);

    const onChangeSearchJanCodeParams = (index: number, value: string) => {
      const newSearchJanCodeParams = searchJanCodeParams.slice();
      newSearchJanCodeParams[index] = value;
      setSearchJanCodeParams(newSearchJanCodeParams);
    };

    const handleSearchModelNameByJANCode = async (index: number) => {
      try {
        LoadingOverlayController.show();
        // JanCode検索
        let model_name = "";
        const janCode = searchJanCodeParams[index];
        if (janCode) {
          const param = {
            jan_code: janCode,
          };
          const response = await getModelNameMaster(param);
          if (response?.data && response.data.length > 0) {
            model_name = response.data[0].name;
          } else {
            ModalController.show({
              message: messages.TEMPLATE.MSG_MODEL_NAME_NOT_EXIST,
              visibleButton2: true,
            });
          }
        }

        const newModelAndQTY = stateForm.model_and_qty.slice();
        newModelAndQTY[index].model = model_name;
        setStateForm((prevState) => ({
          ...prevState,
          model_and_qty: newModelAndQTY,
        }));
      } finally {
        LoadingOverlayController.hide();
      }
    };

    const handleAddItem = () => {
      setSearchJanCodeParams((prev) => [...prev, ""]);
      setStateForm((prev) => ({
        ...prev,
        model_and_qty: [...prev.model_and_qty, inputDefaultItems],
      }));
    };

    const handleChangeModel = (model: string, index: number) => {
      const newModelAndQty = stateForm.model_and_qty.slice();
      newModelAndQty[index].model = model;
      setStateForm((prev) => ({ ...prev, model_and_qty: newModelAndQty }));
    };

    const handelChangeQty = (qty: string, index: number) => {
      const newModelAndQTY = [...stateForm.model_and_qty];
      newModelAndQTY[index].qty = qty;
      setStateForm((prev) => {
        let total_qty = prev.model_and_qty.reduce(
          (accumulation, previous) => accumulation + +previous.qty,
          0,
        );
        total_qty = isNumber(total_qty) ? total_qty : 0;
        return { ...prev, total_qty, model_and_qty: newModelAndQTY };
      });
    };

    const handleDeleteItems = (deleteIndex: number) => {
      if (deleteIndex >= 0) {
        const updateSearchJanCodeParams = [...searchJanCodeParams];
        updateSearchJanCodeParams.splice(deleteIndex, 1);
        setSearchJanCodeParams(updateSearchJanCodeParams);

        const updatedItemList = [...stateForm.model_and_qty];
        updatedItemList.splice(deleteIndex, 1);
        const total_qty = updatedItemList.reduce(
          (accumulation, previous) => accumulation + +previous.qty,
          0,
        );
        setStateForm((prev) => ({
          ...prev,
          total_qty,
          model_and_qty: updatedItemList,
        }));
      }
    };

    const renderModelAndQty = (data: any[]) => {
      return data.map((item, index) => (
        <CancelBtnBadge
          onClick={() => {
            ModalController.show({
              message: "報告内容を削除しますか?",
              visibleButton1: true,
              visibleButton2: true,
              button1: { title: messages.COMMON.BUTTON.CANCEL },
              handlePressButton2: () => handleDeleteItems(index),
            });
          }}
          key={index}
          sx={{ width: "100%" }}
          componentsProps={{ badge: { style: { right: "3%", top: "4%" } } }}
        >
          <Paper variant="outlined" sx={{ width: "100%", p: 2 }} key={index}>
            <Stack>
              <Box sx={{ display: "flex", mb: 4 }}>
                <TextField
                  label={"EAN/JANCode"}
                  value={searchJanCodeParams[index]}
                  onChange={(e) =>
                    onChangeSearchJanCodeParams(index, e.target.value)
                  }
                  sx={{ flex: 1 }}
                />
                <Box sx={styles.searchBtn}>
                  <Button
                    sx={{ minWidth: 60 }}
                    onClick={() => handleSearchModelNameByJANCode(index)}
                  >
                    検索
                  </Button>
                </Box>
              </Box>
              <TextField
                inputProps={{ maxLength: 30 }}
                label="Model Name"
                value={item.model}
                onChange={(e) => handleChangeModel(e.target.value, index)}
              />
              <TextField
                inputProps={{ maxLength: 6 }}
                label="Quantity"
                value={item.qty}
                onChange={(e) => handelChangeQty(e.target.value, index)}
                onBlur={() => onValidateQuantity()}
                error={
                  qtyErrorIndex.length > 0 && qtyErrorIndex.includes(index)
                }
                helperText={
                  qtyErrorIndex.includes(index) && formError.model_and_qty
                }
              />
            </Stack>
          </Paper>
        </CancelBtnBadge>
      ));
    };

    return (
      <Box>
        <FormControl fullWidth>
          <InputLabel sx={{ mb: 2 }}>
            Total Quantity {stateForm.total_qty}
          </InputLabel>
          <FormGroup>
            <Stack sx={styles.formStack}>
              {renderModelAndQty(stateForm.model_and_qty)}
              <Button onClick={handleAddItem}>Add Models</Button>
            </Stack>
          </FormGroup>
        </FormControl>
      </Box>
    );
  };

  /**  アコーディオンセクション② Container condition photos */
  const renderItem2 = () => {
    return (
      <FormGroup>
        <Stack sx={styles.formStack}>
          <CustomImagePicker
            title={"Container condition + EIR"}
            props={[stateForm, setStateForm, uploadImage]}
            maxImage={maxNumberImageDefault}
            imageArrayName={"container_condition_photo"}
          />
        </Stack>
      </FormGroup>
    );
  };

  /**  アコーディオンセクション③ Other photos */
  const renderItem3 = () => {
    return (
      <FormGroup>
        <Stack sx={styles.formStack}>
          <CustomImagePicker
            title={"Other photos"}
            props={[stateForm, setStateForm, uploadImage]}
            maxImage={maxNumberImageDefault}
            imageArrayName={"other_document_photo"}
          />
        </Stack>
      </FormGroup>
    );
  };

  /**  アコーディオンセクション④ Loading situation photos */
  const renderItem4 = () => {
    return (
      <FormGroup>
        <Stack sx={styles.formStack}>
          <CustomImagePicker
            title={"Loading situation photos"}
            props={[stateForm, setStateForm, uploadImage]}
            maxImage={maxNumberImageDefault}
            imageArrayName={"loading_situation_photo"}
          />
        </Stack>
      </FormGroup>
    );
  };

  // 保存 =====================================
  const onSubmit = async () => {
    // 画像アップロード
    let newContainerConditionPhoto: Array<Type.ImageInfoType> =
      stateForm.container_condition_photo ?? [];
    let newOtherDocumentPhoto: Array<Type.ImageInfoType> =
      stateForm.other_document_photo ?? [];
    let newLoadingSituationPhoto: Array<Type.ImageInfoType> =
      stateForm.loading_situation_photo ?? [];
    if (stateForm.container_condition_photo) {
      newContainerConditionPhoto = await uploadImageToS3(
        stateForm.container_condition_photo,
      );
    }
    if (stateForm.other_document_photo) {
      newOtherDocumentPhoto = await uploadImageToS3(
        stateForm.other_document_photo,
        Date.now(),
      );
    }
    if (stateForm.loading_situation_photo) {
      newLoadingSituationPhoto = await uploadImageToS3(
        stateForm.loading_situation_photo,
        Date.now(),
      );
    }
    let newStateForm = {
      ...stateForm,
      container_condition_photo: newContainerConditionPhoto,
      other_document_photo: newOtherDocumentPhoto,
      loading_situation_photo: newLoadingSituationPhoto,
    };
    setStateForm(newStateForm);
  };

  return (
    <Box height={"auto"}>
      <Stack>
        <Card>
          <CardHeader title="基本情報" sx={styles.header} />
          <CardContent>
            <FormGroup>
              <Stack sx={styles.formStack}>
                <DatePickerCustom
                  label={
                    step > REPORTER_STEP ? (
                      <>
                        <LabelRequired title={"Loading Date(YYYY/MM/DD)"} />
                      </>
                    ) : "Loading Date(YYYY/MM/DD)"

                  }
                  value={stateForm.loading_date}
                  onChange={onChangeDate("loading_date")}
                  onBlur={() =>
                    validation(
                      setFormError,
                      formError,
                      "loading_date",
                      stateForm.loading_date,
                    )
                  }
                  error={formError.loading_date.length > 0}
                  helperText={formError.loading_date}
                />
                <SelectLabel
                  label={
                    step > REPORTER_STEP ? (
                      <>
                        <LabelRequired title={"Warehouse"} />
                      </>
                    ) : "Warehouse"
                  }
                  value={stateForm.warehouse?.SK ?? ""}
                  onChange={(e) => {
                    onChangeSelect("warehouse")(
                      listReportItem.listWarehouse,
                      e,
                    );
                  }}
                  onBlur={() =>
                    validation(
                      setFormError,
                      formError,
                      "warehouse",
                      stateForm.warehouse,
                    )
                  }
                  error={formError.warehouse.length > 0}
                  helperText={formError && formError.warehouse}
                >
                  {renderMenuItem(listReportItem.listWarehouse, "SK", "name")}
                </SelectLabel>
                <TextField
                  label="Other（Warehouse）"
                  inputProps={{ maxLength: 16 }}
                  value={stateForm.warehouse_other}
                  disabled={!Boolean(stateForm.warehouse?.is_manual_input ?? true)}
                  onChange={onChangeText("warehouse_other")}
                />
                <SelectLabel
                  label={
                    step > REPORTER_STEP ? (
                      <>
                        <LabelRequired title={"Load by"} />
                      </>
                    ) : "Load by"
                  }
                  value={stateForm.load_by?.SK ?? ""}
                  onChange={(e) => {
                    onChangeSelect("load_by")(listReportItem.listLoadBy, e);
                  }}
                  onBlur={() =>
                    validation(
                      setFormError,
                      formError,
                      "load_by",
                      stateForm.load_by,
                    )
                  }
                  error={formError.load_by.length > 0}
                  helperText={formError && formError.load_by}
                >
                  {renderMenuItem(listReportItem.listLoadBy, "SK", "name")}
                </SelectLabel>
                <TextField
                  label="Invoice No."
                  inputProps={{ maxLength: 30 }}
                  value={stateForm.invoice_no}
                  onChange={onChangeText("invoice_no")}
                />
                <TextField
                  label="Booking No."
                  inputProps={{ maxLength: 30 }}
                  value={stateForm.booking_no}
                  onChange={onChangeText("booking_no")}
                />
                <SelectLabel
                  label={
                    step > REPORTER_STEP ? (
                      <>
                        <LabelRequired title={"Commodity"} />
                      </>
                    ) : "Commodity"
                  }
                  value={stateForm.commodity?.SK ?? ""}
                  onChange={(e) => {
                    onChangeSelect("commodity")(
                      listReportItem.listCommodity,
                      e,
                    );
                  }}
                  onBlur={() =>
                    validation(
                      setFormError,
                      formError,
                      "commodity",
                      stateForm.commodity,
                    )
                  }
                  error={formError.commodity.length > 0}
                  helperText={formError && formError.commodity}
                >
                  {renderMenuItem(listReportItem.listCommodity, "SK", "name")}
                </SelectLabel>
                <TextField
                  label="Other（Commodity）"
                  inputProps={{ maxLength: 30 }}
                  value={stateForm.commodity_other}
                  disabled={!Boolean(stateForm.commodity?.is_manual_input ?? true)}
                  onChange={onChangeText("commodity_other")}
                />
                <SelectLabel
                  label="Container type"
                  value={stateForm.container_type?.SK ?? ""}
                  onChange={(e) => {
                    onChangeSelect("container_type")(
                      listReportItem.listContainerType,
                      e,
                    );
                  }}
                >
                  {renderMenuItem(
                    listReportItem.listContainerType,
                    "SK",
                    "name",
                  )}
                </SelectLabel>
                <TextField
                  label={
                    <>
                      <LabelRequired title={"Container No."} />
                    </>
                  }
                  inputProps={{ maxLength: 20 }}
                  value={stateForm.container_no}
                  onChange={onChangeTextUpperCase("container_no")}
                  onBlur={() =>
                    validation(
                      setFormError,
                      formError,
                      "container_no",
                      stateForm.container_no,
                    )
                  }
                  error={formError.container_no.length > 0}
                  helperText={formError.container_no}
                />
                <TextField
                  label="Seal No."
                  inputProps={{ maxLength: 12 }}
                  value={stateForm.seal_no}
                  onChange={onChangeText("seal_no")}
                />
                <TextField
                  label="Remark"
                  inputProps={{ maxLength: 90 }}
                  value={stateForm.remark}
                  onChange={onChangeText("remark")}
                />
              </Stack>
            </FormGroup>
          </CardContent>
        </Card>
        <AccordionSection title={"Models"} children={renderItem1()} />
        <AccordionSection
          title={"Container condition photos"}
          children={renderItem2()}
        />
        <AccordionSection
          title={"Loading situation photos"}
          children={renderItem4()}
        />
        <AccordionSection title={"Other photos"} children={renderItem3()} />
      </Stack>
    </Box>
  );
};

export default forwardRef(LoadingReport);

const styles = {
  header: { bgcolor: Colors.MAIN_GREEN_LIGHT },
  formStack: { pb: 0, width: "100%" },
  searchBtn: {
    display: "flex",
    alignSelf: "flex-end",
    ml: 1,
    mb: "10px",
  },
} as const;
