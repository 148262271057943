import React, { VFC, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  FormGroup,
  TextField,
} from "@mui/material";
import { useEffect } from "react";
import { Validation } from "@validation";
import LabelRequired from "components/atoms/LabelRequired";
import CheckboxLabel from "components/atoms/CheckboxLabel";

export interface IData {
  name: string;
  isDefault: boolean;
  isManualInput: boolean;
}

interface IProps {
  data: IData;
  open: boolean;
  onClose: Function;
  onChange: Function;
  tlgtMasterTypeName: string;
}

const TlgtMasterEditDialog: VFC<IProps> = ({
  data,
  open,
  onClose,
  onChange,
  tlgtMasterTypeName,
}: IProps) => {
  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------
  const [name, setName] = useState("");
  const [errorText, setErrorText] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [isDefault, setIsDefault] = useState<boolean>(false);
  const [isManualInput, setIsManualInput] = useState<boolean>(false);
  //M1とM5のみ「手入力有り」の設定を可能とする
  const manualInputMasterTypeName = ["M1_Warehouse", "M5_Commodity"];

  useEffect(() => {
    const setInitialData = async () => {
      setName(data.name);
      setErrorText("");
      setDisabled(true);
      setIsDefault(data.isDefault);
      setIsManualInput(data.isManualInput);
    };

    if (open) {
      setInitialData();
    }
  }, [open, data]);

  // ------------------------------------------------------------------
  // 入力
  // ------------------------------------------------------------------
  const changeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const validName = () => {
    const error_text = Validation.validate({
      type: "text",
      name: "名称",
      value: name,
      required: true,
      max_length: 80,
    });
    setErrorText(error_text);
    setDisabled(error_text.length > 0 || data.name == name);
  };

  const checkIsDisabled = (is_default: boolean) => {
    const error_text = Validation.validate({
      type: "text",
      name: "名称",
      value: name,
      required: true,
      max_length: 80,
    });
    if (!error_text.length) {
      setDisabled(data.isDefault === is_default);
    }
  };

  const checkManualInputDisabled = (is_manual_input: boolean) => {
    const error_text = Validation.validate({
      type: "text",
      name: "名称",
      value: name,
      required: true,
      max_length: 80,
    });
    if (!error_text.length) {
      setDisabled(data.isManualInput === is_manual_input);
    }
  };

  return (
    <Dialog
      maxWidth="sm"
      sx={{ "& .MuiDialog-paper": { width: "80%" } }}
      open={open}
    >
      <DialogContent>
        <FormGroup>
          <TextField
            label={
              <>
                <LabelRequired title={"名称"} />
              </>
            }
            value={name}
            onChange={changeName}
            onBlur={validName}
            error={errorText.length > 0}
            helperText={errorText}
            inputProps={{
              maxLength: 80,
            }}
          />
          {manualInputMasterTypeName.includes(tlgtMasterTypeName) && (
            <div>
              <CheckboxLabel
                label="手入力有り"
                checked={isManualInput}
                onChange={(e) => {
                  setIsManualInput(e.target.checked);
                  checkManualInputDisabled(e.target.checked);
                }}
                formSx={{ pt: 2 }}
              />
            </div>
          )}
          <div>
            <CheckboxLabel
              label="デフォルト値"
              checked={isDefault}
              onChange={(e) => {
                setIsDefault(e.target.checked);
                checkIsDisabled(e.target.checked);
              }}
            />
          </div>
        </FormGroup>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          onClick={() => {
            onClose();
          }}
          variant="outlined"
        >
          キャンセル
        </Button>

        <Button
          onClick={() => {
            const response: IData = {
              name: name,
              isDefault: isDefault,
              isManualInput: isManualInput,
            };
            onChange(response);
            onClose();
          }}
          disabled={disabled}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TlgtMasterEditDialog;
