import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import FullScreenDialog from "components/atoms/FullScreenDialog";
import ModalController from "@shared-components/modal/ModalController";
import messages from "config/messages";
import {
  BUTTON_EDIT_TEMPLATE_COMPLETE,
  TEMPLATE_EDIT_TYPE,
} from "@shared-constants";
import { createObjectURL, getFileByUrl } from "@utils/index";
import RiskyReport from "./RiskyReport";
import _ from "lodash";
import { STORAGE, storeData } from "@utils/Storage";
import TemplateForkliftReport from "./TemplateForkliftReport";
import TemplateExcelReport from "./TemplateExcelReport";
import TemplateIncidentDamageReport from "./TemplateIncidentDamageReport";
import { TemplateExcelDataInfo } from "services/models";
import InspectionReport from "./InspectionReport";
import { getInfoSizeTemplate } from "@utils/template/excelTemplate";
import LoadingReport from "./TemplateLoadingReport";
import ReceivingProblemTag from "./TemplateReceivingProblemTag";
import TemplateExteriorDamagedTCReport from "./TemplateExteriorDamagedTCReport";
import TemplateExteriorDamagedDCReport from "./TemplateExteriorDamagedDCReport";

const fileType = "text/html";
type IType = keyof typeof TEMPLATE_EDIT_TYPE;

interface IProps {
  open: boolean;
  setOpen: Function;
  type: IType | string;
  infoToEdit: any;
  template_path: string;
  isManage?: boolean;
  onHandleTemplate: (
    newTemplate: {
      type: string;
      uri: string;
      name: string;
      templateEditInfo: any;
    },
    template_image: string,
  ) => void;
  completeBtnTitle?: string;
  template_id?: string;
  work_id?: string;
  data_info?: Array<TemplateExcelDataInfo>;
  is_landscape?: boolean;
  is_hand_over?: boolean;
  activityBaseId?: string;
  step: number;
  created_by: string;
  project_id: string;
  approval_status?: string;
  isMultiple?: boolean;
  extraPage?: Array<{
    template_path: string;
    data_info: Array<TemplateExcelDataInfo>;
  }>;
}

const EditTemplate = ({
  open,
  setOpen,
  type,
  infoToEdit,
  template_path,
  isManage = false,
  onHandleTemplate,
  completeBtnTitle = BUTTON_EDIT_TEMPLATE_COMPLETE.EDIT,
  template_id = "",
  work_id = "",
  data_info = [],
  is_landscape,
  is_hand_over = false,
  activityBaseId,
  step = 0,
  created_by,
  project_id,
  isMultiple = false,
  extraPage = [],
  approval_status = "",
}: IProps) => {
  const templateRef = useRef<any>();
  const [htmlString, setHtmlString] = useState<string>("");
  const [originHtmlString, setOriginHtmlString] = useState<string>("");
  const [imgPath, setImgPath] = useState<string>();
  const [listHtml, setListHtml] = useState<string[]>([]);

  // ====================================
  // プレビュー画像作成
  // ====================================
  const getSvgSize = (html: string) => {
    let w = 650;
    let h = 1050;
    let size = { w, h, view_w: 0, view_h: 0 };
    switch (type) {
      case TEMPLATE_EDIT_TYPE.RISKY_REPORT:
        const h_r = 650;
        const w_r = 1050;
        const page_margin = 70;
        var page_r = (html.match(/<div class="div-page-break">/g) || []).length;

        if (page_r < 1) {
          size.w = w_r;
          size.h = h_r;
        } else {
          size.w = w_r;
          size.h = (h_r + page_margin) * page_r;
        }
        break;
      case TEMPLATE_EDIT_TYPE.LOADING_REPORT:
        const h_l = 1050;
        const w_l = 800;
        const page_margin_l = 90;
        var page_l =
          (html.match(/<div class="div-page-break">/g) || []).length - 2;
        size.w = w_l;
        size.h = (h_l + page_margin_l) * page_l;
        break;
      case TEMPLATE_EDIT_TYPE.TEMPLATE_INCIDENT_DAMAGE_REPORT:
        const h_d = 750;
        const w_d = 1100;
        var page_f = (html.match(/<div class="div-page-break">/g) || []).length;

        size.w = w_d;
        size.h = h_d * (page_f + 1);
        break;
      case TEMPLATE_EDIT_TYPE.TEMPLATE_RECEIVING_PROBLEM_TAG:
        const h_t = 710;
        const w_t = 1110;
        const page_margin_t = 15;
        const page_t = (html.match(/<div class="page">/g) || []).length - 2;
        size.w = w_t;
        size.h = h_t * page_t + page_margin_t * (page_t - 1);
        break;

      case TEMPLATE_EDIT_TYPE.TEMPLATE_FORKLIFT_REPORT:
        const h_f = 700;
        const w_f = 1090;
        var page_f = (html.match(/<div class="div-page-break">/g) || []).length;

        size.w = w_f;
        size.h = h_f * (page_f + 1);
        break;
      case TEMPLATE_EDIT_TYPE.INSPECTION_REPORT:
        const h_i = 1110;
        const w_i = 790;
        var page_f = (html.match(/<div class="div-page-break">/g) || []).length;

        size.w = w_i;
        size.h = h_i * (page_f - 2);
        break;
      case TEMPLATE_EDIT_TYPE.TEMPLATE_EXCEL_REPORT:
      case TEMPLATE_EDIT_TYPE.TEMPLATE_EXCEL_REPORT_SETTING:
        const { width: w_e_s, height: h_e_s, zoom } = getInfoSizeTemplate(html);
        var page_e_s = (html.match(/<div class="div-page-break">/g) || [])
          .length;

        size.w = Number(w_e_s);
        size.h = Number(h_e_s) * page_e_s;
        size.view_w = size.w / Number(zoom);
        size.view_h = size.h / Number(zoom);
        break;
      case TEMPLATE_EDIT_TYPE.EXTERIOR_DAMAGED_TC_REPORT:
      case TEMPLATE_EDIT_TYPE.EXTERIOR_DAMAGED_DC_REPORT:
        size.w = 715;
        size.h = 1055;
        break;
      default:
        break;
    }
    if (!size.view_h) size.view_h = size.h;
    if (!size.view_w) size.view_w = size.w;
    return size;
  };

  useEffect(() => {
    if (htmlString.length === 0) return;
    // html要素作成
    const doc = document.createElement("div");
    doc.appendChild(
      document.createRange().createContextualFragment(htmlString),
    );
    var serializeHtml = new XMLSerializer().serializeToString(doc);
    const { w, h, view_w, view_h } = getSvgSize(serializeHtml);

    const data = `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0, 0, ${view_w}, ${view_h}"
         width="${w}" height="${h}" style="background-color: white;">
      <foreignObject width="100%" height="100%">
        ${serializeHtml}
      </foreignObject>
    </svg>
  `;
    // svgファイルに変換
    const svg = new Blob([data], { type: "image/svg+xml" });

    // svgを表示する場合 --->>
    // const url = createObjectURL(svg);
    // setImgPath(url);
    // svgを表示する場合 --->>

    // svgをpngに変換
    var reader = new FileReader();
    reader.readAsDataURL(svg);
    reader.onloadend = function () {
      var base64 = reader.result;
      const img = new Image();
      img.addEventListener("load", () => {
        const canvas = document.createElement("canvas");

        canvas.setAttribute("width", w.toString());
        canvas.setAttribute("height", h.toString());

        const context = canvas.getContext("2d");
        context?.drawImage(img, 0, 0, w, h);
        const dataUrl = canvas.toDataURL("image/png");
        setImgPath(dataUrl);
      });

      img.src = typeof base64 === "string" ? base64 : "";
    };
  }, [htmlString]);

  // ====================================
  // テンプレートHTML取得
  // ====================================
  useEffect(() => {
    const getHtml = async () => {
      if (!template_path) return;

      const f = await getFileByUrl(template_path, "template.html", "text/html");
      const html_string = await f.text();

      let newListHtml = [html_string];
      for (let index = 0; index < extraPage.length; index++) {
        const uri = extraPage[index].template_path;
        const f = await getFileByUrl(uri, "template.html", "text/html");
        const html_string = await f.text();
        newListHtml.push(html_string);
      }

      setListHtml(newListHtml);
      setHtmlString(html_string);
      setOriginHtmlString(html_string);
    };
    if (open && originHtmlString === "" && htmlString === "") {
      getHtml();
    }
  }, [template_path, open, htmlString, originHtmlString, extraPage]);

  // ====================================
  // ボタン制御
  // ====================================z
  // 閉じる
  const handleClose = () => {
    setOpen(false);
  };

  // 完了
  const onSubmit = async () => {
    if (getDisabledSubmit()) return;
    if (templateRef.current) {
      if (templateRef.current.onSubmit) {
        const response = await templateRef.current.onSubmit();
        if (response === false) return;
      }
      handleSetTemplate();
    }
    handleClose();
  };

  const handleSetTemplate = () => {
    if (templateRef.current) {
      const stateForm = templateRef.current.getStateForm();
      getHtmlString().then((html_string) => {
        const blob = new Blob([html_string], { type: "image/html" });
        const path = createObjectURL(blob);
        const fileName = `Genbastar_${new Date().getTime().toString()}.html`;
        onHandleTemplate(
          {
            type: fileType,
            uri: path ?? "",
            name: fileName,
            templateEditInfo: stateForm,
          },
          imgPath ?? "",
        );
      });
    }
  };

  const getHtmlString = async (): Promise<string> => {
    if (templateRef.current && templateRef.current.getHtmlString) {
      return await templateRef.current.getHtmlString();
    }
    return htmlString;
  };

  // プレビュー
  const onPreview = () => {
    //別タブで開く
    const data = {
      type: "url",
      html: imgPath,
    };
    storeData(STORAGE.PREVIEW_DATA, JSON.stringify(data));
    window.open("/preview");
  };

  const hasUpdateData = () => {
    if (templateRef?.current) {
      const stateForm = templateRef?.current.getStateForm();
      const originData = templateRef?.current.getOriginStateForm();
      if (infoToEdit?.memo === undefined) {
        return stateForm && originData && !_.isEqual(stateForm, originData);
      } else {
        return stateForm && !_.isEqual(stateForm, infoToEdit);
      }
    }
    return false;
  };

  const confirmSave = () => {
    if (hasUpdateData()) {
      ModalController.show({
        message: messages.COMMON.MSG_CONFIRM_GO_BACK,
        visibleButton1: true,
        visibleButton2: true,
        handlePressButton1: handleClose,
        handlePressButton2: () => onSubmit(),
        button1: { title: messages.COMMON.BUTTON.CANCEL },
      });
      return;
    }
    handleClose();
  };

  const getDisabledSubmit = () => {
    if (templateRef?.current?.disabledSubmit) {
      return templateRef?.current.disabledSubmit();
    }
    return false;
  };

  const disabledSubmit = useMemo(() => {
    return getDisabledSubmit();
  }, [templateRef.current]);

  const disabledPreview = useMemo(() => {
    if (templateRef?.current?.disabledPreview) {
      return templateRef?.current.disabledPreview();
    }
    return false;
  }, [templateRef.current]);

  const TemplateInput = useMemo(() => {
    switch (type) {
      case TEMPLATE_EDIT_TYPE.RISKY_REPORT:
        return (
          <RiskyReport
            ref={templateRef}
            infoToEdit={infoToEdit}
            htmlString={originHtmlString}
            setHtmlString={setHtmlString}
            isManage={isManage}
            open={open}
          />
        );
      case TEMPLATE_EDIT_TYPE.LOADING_REPORT:
        return (
          <LoadingReport
            ref={templateRef}
            infoToEdit={infoToEdit}
            htmlString={originHtmlString}
            setHtmlString={setHtmlString}
            open={open}
            step={step}
          />
        );
      case TEMPLATE_EDIT_TYPE.TEMPLATE_FORKLIFT_REPORT:
        return (
          <TemplateForkliftReport
            ref={templateRef}
            infoToEdit={infoToEdit}
            htmlString={originHtmlString}
            setHtmlString={setHtmlString}
            isManage={isManage}
            open={open}
            template_id={template_id}
            work_id={work_id}
            is_hand_over={is_hand_over}
            approval_status={approval_status}
          />
        );
      case TEMPLATE_EDIT_TYPE.INSPECTION_REPORT:
        return (
          <InspectionReport
            ref={templateRef}
            infoToEdit={infoToEdit}
            htmlString={originHtmlString}
            setHtmlString={setHtmlString}
            isManage={isManage}
            open={open}
            handleSetTemplate={handleSetTemplate}
            handleClose={handleClose}
          />
        );
      case TEMPLATE_EDIT_TYPE.TEMPLATE_EXCEL_REPORT:
      case TEMPLATE_EDIT_TYPE.TEMPLATE_EXCEL_REPORT_SETTING:
        return (
          <TemplateExcelReport
            ref={templateRef}
            infoToEdit={infoToEdit}
            htmlString={originHtmlString}
            setHtmlString={setHtmlString}
            isManage={isManage}
            excelDataInfo={data_info}
            open={open}
            isSetting={type == TEMPLATE_EDIT_TYPE.TEMPLATE_EXCEL_REPORT_SETTING}
            activityBaseId={activityBaseId}
            step={step}
            isMultiple={isMultiple}
            extraPage={extraPage?.map((value) => value.data_info) ?? []}
            onChangePage={(page) => {
              if (listHtml.length > page) {
                setOriginHtmlString(listHtml[page]);
                setHtmlString(listHtml[page]);
              }
            }}
            listHtmlString={listHtml}
          />
        );
      case TEMPLATE_EDIT_TYPE.TEMPLATE_INCIDENT_DAMAGE_REPORT:
        return (
          <TemplateIncidentDamageReport
            ref={templateRef}
            infoToEdit={infoToEdit}
            htmlString={originHtmlString}
            setHtmlString={setHtmlString}
            isManage={isManage}
            step={step}
            open={open}
            handleSetTemplate={handleSetTemplate}
            handleClose={handleClose}
            created_by={created_by}
          />
        );
      case TEMPLATE_EDIT_TYPE.TEMPLATE_RECEIVING_PROBLEM_TAG:
        return (
          <ReceivingProblemTag
            ref={templateRef}
            infoToEdit={infoToEdit}
            htmlString={originHtmlString}
            setHtmlString={setHtmlString}
            isManage={isManage}
            step={step}
            open={open}
          // handleSetTemplate={handleSetTemplate}
          // handleClose={handleClose}
          />
        );
      case TEMPLATE_EDIT_TYPE.EXTERIOR_DAMAGED_TC_REPORT:
        return (
          <TemplateExteriorDamagedTCReport
            ref={templateRef}
            infoToEdit={infoToEdit}
            htmlString={originHtmlString}
            setHtmlString={setHtmlString}
            isManage={isManage}
            step={step}
            open={open}
            project_id={project_id}
          />
        );
      case TEMPLATE_EDIT_TYPE.EXTERIOR_DAMAGED_DC_REPORT:
        return (
          <TemplateExteriorDamagedDCReport
            ref={templateRef}
            infoToEdit={infoToEdit}
            htmlString={originHtmlString}
            setHtmlString={setHtmlString}
            isManage={isManage}
            step={step}
            open={open}
            project_id={project_id}
          />
        );
      default:
        return <></>;
    }
  }, [
    originHtmlString,
    infoToEdit,
    isManage,
    open,
    data_info,
    imgPath,
    htmlString,
    type,
    activityBaseId,
    isMultiple,
    extraPage,
    listHtml,
  ]);

  return (
    <FullScreenDialog open={open} onClose={handleClose} title={"編集"}>
      <Tooltip title="閉じる">
        <IconButton
          onClick={() => confirmSave()}
          sx={{
            position: { xs: "absolute", md: "fixed" },
            right: 20,
            top: 15,
            zIndex: 2000,
          }}
        >
          <Close sx={{ color: "white" }} />
        </IconButton>
      </Tooltip>

      <Box sx={{ mt: 1 }}>
        <Grid container columnSpacing={2}>
          {/* input */}
          <Grid item xs={12} md={4}>
            {TemplateInput}
          </Grid>

          {/* preview */}
          <Grid
            item
            xs={12}
            md={8}
            position={"sticky"}
            height={{ xs: "auto", md: "70vh" }}
            top={106}
          >
            <Card sx={{ height: "70vh", display: { xs: "none", md: "grid" } }}>
              <CardContent sx={{ overflow: "auto" }}>
                <img
                  src={imgPath}
                  style={{ objectFit: "contain", width: "100%" }}
                />
              </CardContent>
            </Card>
            <Stack direction="row" justifyContent="flex-end" mt={1}>
              <Button onClick={onPreview} disabled={disabledPreview}>
                プレビュー
              </Button>
              <Button
                onClick={onSubmit}
                color="secondary"
                disabled={disabledSubmit}
              >
                {completeBtnTitle}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </FullScreenDialog>
  );
};

export default EditTemplate;
