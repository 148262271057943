import {
  METHOD_API,
  createConnector,
  getParamsHelp,
  prefixApi,
} from "./Connector";

export const getListLock = (category?: string) => {
  let url_params = "";
  if (category) {
    const params = { category: category };
    url_params = `?${getParamsHelp(params)}`;
  }
  return createConnector(METHOD_API.GET, `${prefixApi}/lock-list${url_params}`);
};

export const getLockById = (params: { SK: string; contract_id?: string }) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/lock?${getParamsHelp(params)}`,
  );
};

export const deleteLock = (data: any) => {
  return createConnector(METHOD_API.DELETE, `${prefixApi}/lock`, data);
};
