import { Button } from "@mui/material";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import ModalController from "@shared-components/modal/ModalController";
import SnackbarController from "@shared-components/snackBar/SnackbarController";
import {
  LIST_GROUP,
  LIST_MASTER,
  LIST_EXPORT_CSV,
  LIST_ACCOUNT,
} from "shared/constants/MasterInfo";
import { insertExportLog } from "@utils/LogIO";
import { ExportMasterParamater } from "services/models";
import { downloadXlsx, getExportMasterFileName } from "@utils/CSV";
import messages from "config/messages";
import { exportMaster, getMasterTypeOfUserMade } from "@api/master";
import { Download } from "@mui/icons-material";
import { MASTER_TYPE } from "@shared-constants";

type MasterExportButtonOption = {
  masterType: string;
  activityBaseId: string;
  userInfo?: any;
  isDisabled?: boolean;
};

const MANUAL_INPUT_FLG = 1
const DEFAULT_FLG = 1

const MasterExportButton = ({
  masterType,
  activityBaseId,
  userInfo,
  isDisabled,
}: MasterExportButtonOption) => {
  const accountCreate = LIST_ACCOUNT.filter((value) => (value.id === "ACCOUNT_CREATE"));

  const handleExport = async () => {
    try {
      LoadingOverlayController.show();
      // カスタマイズ帳票用マスタ情報から該当するマスタ情報を取得する
      const masterInfo = LIST_MASTER.find((item) => item.id === masterType);
      // カスタマイズ帳票用マスタに該当があるか、ユーザー作成マスタであればCSV出力処理を開始する
      if (masterInfo || masterType.includes(MASTER_TYPE)) {
        const formData: ExportMasterParamater = {
          master: masterInfo ? masterInfo.id : masterType,
          master_name: masterInfo ? masterInfo.name : "",
          activity_base_id: activityBaseId,
        };
        const res = await exportMaster(formData);
        let columns: any = [];
        if (formData.master === "ACCOUNT") {
          const id = accountCreate[0]?.id;
          columns = LIST_EXPORT_CSV[id] || [];
        } else if (formData.master.includes(MASTER_TYPE)) {
          // ユーザー作成マスタの場合、基本となる項目＋ユーザー作成マスタで追加された項目が出力項目となる
          columns = [...LIST_EXPORT_CSV[LIST_GROUP.USER_MASTER.id]];
          const master_type = await getMasterTypeOfUserMade(formData.master);
          formData.master_name = master_type?.name ?? "";
          if (master_type && master_type.custom_item) {
            // ユーザー作成マスタ設定情報からマスタ名と追加された項目の情報を取得する
            master_type.custom_item.forEach((v: any, i: number) => {
              columns.push({ header: v.name, key: "item" + String(i + 1) });
            });
          }
        } else {
          columns = LIST_EXPORT_CSV[formData.master] || [];
        }

        if (res && "data_length" in res && "is_exceeded" in res) {
          if (res.is_exceeded) {
            //成功ダイアログ
            ModalController.show({
              message: messages.MASTER.MSG_DOWNLOAD_COUNT_EXCEEDED(res.data_length),
              visibleButton2: true,
            });
          } else {
            SnackbarController.show({
              severity: "success",
              message: messages.MASTER.MSG_DOWNLOAD_COUNT(res.data_length),
            });
          }

          const isKeyExist = columns.some((column: any) => {
            const keyName = column?.key;
            return keyName === "is_default" || keyName === "is_manual_input"
          })

          if(res.data.length > 0 && isKeyExist){
            res.data.map((dataInfo :any) => {
              dataInfo.is_default = dataInfo?.is_default ? DEFAULT_FLG : "";
              dataInfo.is_manual_input = dataInfo?.is_manual_input ? MANUAL_INPUT_FLG : "";
              return dataInfo;
            });
          }

          await downloadXlsx({
            data: res.data,
            columns: columns,
            filename: getExportMasterFileName(formData.master_name),
          });
          insertExportLog(res.data_length, userInfo, formData.master, activityBaseId);
        } else {
          throw new Error("出力データが取得できませんでした");
        }
      } else {
        ModalController.show({
          message: `存在しないマスタ種類が指定されました [${masterType}]`,
          visibleButton2: true,
        });
      }
    } catch (error: any) {
      if (!ModalController.isShowing())
        ModalController.show({
          message: error?.detail,
          visibleButton2: true,
        });
      console.log("error handleExport", error);
    } finally {
      LoadingOverlayController.hide();
    }
  }

  return (
    <Button
      onClick={handleExport}
      endIcon={<Download />}
      disabled={isDisabled}
      color="secondary"
    >
      CSV出力
    </Button>
  )
}

export default MasterExportButton;