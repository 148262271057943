import { useCallback, useEffect, useState, VFC } from "react";
import {
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import GenericTemplate from "@template/index";
import { AddCircle } from "@mui/icons-material";
import { Colors } from "@template/style";
import { useHistoryCustom } from "shared/hook/useHistoryCustom";
import { TYPES } from "store/types";
import { useDispatch } from "react-redux";
// import { getProjectDefaultApi } from "@api/project";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import { getDashboardInfoApi } from "@api/work";
import { cloneDeep } from "lodash";
import AvatarRes from "components/atoms/AvatarRes";
import { getUserInfo } from "@utils/index";
import { StatisticalBoardType, USER_ROLES } from "@shared-constants";
import formatDateToString, { convertJPDateTime } from "@utils/DateFormat";
import MessagesDialog from "./MessagesDialog";

export const WorkType: { [fieldName: string]: string } = {
  reject: "REJECT",
  cancel: "CANCEL",
  draft: "DRAFT",
  finish: "FINISH",
  pending: "NOT_APPROVED",
};

interface IDashboardInfo {
  title: string;
  count: number;
  work_type: string;
}

const initital: IDashboardInfo[] = [
  { title: "承認待ち", count: 0, work_type: "pending" },
  { title: "一時保存", count: 0, work_type: "draft" },
  { title: "差戻", count: 0, work_type: "reject" },
  { title: "却下", count: 0, work_type: "cancel" },
  { title: "承認完了", count: 0, work_type: "finish" },
];

interface ISection {
  role: Array<string>;
  type: Array<string>;
}
const Section: Array<ISection> = [
  {
    role: [USER_ROLES.ADMIN.value],
    type: [StatisticalBoardType.request, StatisticalBoardType.register],
  },
  {
    role: [USER_ROLES.EDITOR.value, USER_ROLES.MEMBER.value],
    type: [StatisticalBoardType.register, StatisticalBoardType.request],
  },
];

const DashboardScreen: VFC = () => {
  const [dashboardRegisterInfo, setDashboardRegisterInfo] = useState<
    IDashboardInfo[]
  >(cloneDeep(initital));
  const [dashboardRequestInfo, setDashboardRequestInfo] = useState<
    IDashboardInfo[]
  >(cloneDeep(initital));
  const [bannerMessages, setBannerMessages] = useState<Array<any>>([]);
  const [open, setOpen] = useState<boolean>(false);
  const userInfo = getUserInfo();
  const history = useHistoryCustom();
  const dispatch = useDispatch();

  const getDashboardInfo = async () => {
    const date_from = convertJPDateTime(
      formatDateToString(new Date(), "YMD") + "T00:00:00",
    );
    const date_to = convertJPDateTime(
      formatDateToString(new Date(), "YMD") + "T23:59:59",
    );
    const res = await getDashboardInfoApi({
      date_from: formatDateToString(date_from, "YMDTHms"),
      date_to: formatDateToString(date_to, "YMDTHms"),
    });
    if (res?.data) {
      const data = res?.data;
      setDashboardRequestInfo((prevState) => {
        prevState.forEach((item) => {
          switch (item.work_type) {
            case "pending":
              item.count = data?.number_report_pending;
              break;
            case "draft":
              item.count = data?.number_report_draft;
              break;
            case "reject":
              item.count = data?.number_report_reject;
              break;
            case "cancel":
              item.count = data?.number_report_cancel;
              break;
            case "finish":
              item.count = data?.number_report_finish;
              break;
          }
        });
        return cloneDeep(prevState);
      });
      setDashboardRegisterInfo((prevState) => {
        prevState.forEach((item) => {
          switch (item.work_type) {
            case "pending":
              item.count = data?.number_owned_report_pending;
              break;
            case "draft":
              item.count = data?.number_owned_report_draft;
              break;
            case "reject":
              item.count = data?.number_owned_report_reject;
              break;
            case "cancel":
              item.count = data?.number_owned_report_cancel;
              break;
            case "finish":
              item.count = data?.number_owned_report_finish;
              break;
          }
        });
        return cloneDeep(prevState);
      });
      setBannerMessages(data?.message ?? []);
    }
  };

  // const getDashboardDefault = async () => {
  //   const res = await getProjectDefaultApi();
  //   if (res?.data) {
  //     dispatch({ type: TYPES.SET_PROJECT_INFO, payload: res?.data });
  //     dispatch({
  //       type: TYPES.SET_SELECTED_PROJECT_ID,
  //       selected_project_id: res?.data.SK,
  //       selected_project_name: res?.data.project_name,
  //     });
  //   }
  // };

  const getData = async () => {
    LoadingOverlayController.show();
    try {
      await getDashboardInfo();
      // await getDashboardDefault();
    } catch (err) {
      console.log(err);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const renderSection = () => {
    const section = Section.find((item) =>
      item.role.includes(userInfo.user_role),
    );
    if (!section) return <></>;

    return section.type.map((item, index) => (
      <StatisticalBoard type={item} key={index} />
    ));
  };

  const StatisticalBoard = useCallback(
    ({ type }: { type: string }) => {
      let title = "";
      let data: IDashboardInfo[] = [];

      switch (type) {
        case StatisticalBoardType.register:
          title = "申請";
          data = cloneDeep(dashboardRegisterInfo);
          break;
        case StatisticalBoardType.request:
          title = "承認依頼";
          data = cloneDeep(dashboardRequestInfo);
          break;
      }

      return (
        <>
          <Typography variant="h5" sx={{ mb: 1 }}>
            {title}
          </Typography>
          <Grid container spacing={3} sx={{ pb: 5 }}>
            {data.map((item, index) => (
              <Grid item xs={4} md={2} key={index}>
                <DashboardCard
                  title={item.title}
                  count={item.count}
                  type={type}
                  work_type={item.work_type}
                  onClick={() => {
                    history.pushWithRef("/work-status", {
                      work_type: WorkType[item.work_type],
                      title: item.title,
                      type: type,
                    });
                  }}
                  disabled={item.count === 0}
                />
              </Grid>
            ))}
          </Grid>
        </>
      );
    },
    [dashboardRegisterInfo, dashboardRequestInfo],
  );

  return (
    <GenericTemplate title="ダッシュボード">
      <Box
        display="flex"
        justifyContent="flex-end"
        flexDirection="row"
        alignItems="center"
      >
        <MessagesDialog
          open={open}
          onClose={(deletedIndex) => {
            setBannerMessages((prev) => {
              return prev.filter((_, index) => !deletedIndex.includes(index));
            });
            setOpen(false);
          }}
          messageData={bannerMessages}
        />
        <IconButton
          onClick={() => {
            setOpen(true);
          }}
          disabled={bannerMessages.length == 0}
        >
          <Badge
            badgeContent={bannerMessages.length}
            max={9}
            overlap="circular"
            color="error"
          >
            <AvatarRes
              src={userInfo?.avatar}
              size="sm"
              sx={{ width: 30, height: 30 }}
            />
          </Badge>
        </IconButton>
        <Typography sx={{ pl: 1 }}>
          <span className="IgnoreExtractRuleTarget">{userInfo?.full_name}</span>
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        flexDirection="row"
        alignItems="center"
      >
        <Typography sx={{ pl: 1 }}>
          {userInfo?.user_role === USER_ROLES.OWNER ? (
            <span>スーパーユーザー</span>
          ) : (
            <span>
              <span className="IgnoreExtractRuleTarget">
                {userInfo?.location_name}
              </span>
            </span>
          )}
        </Typography>
      </Box>

      {renderSection()}

      {userInfo.user_role !== USER_ROLES.OWNER.value && (
        <Button
          onClick={() => {
            history.pushWithRef("select-report");
          }}
          color="secondary"
          sx={{
            position: "fixed",
            margin: 0,
            top: "auto",
            right: { xs: 20, md: 40 },
            bottom: { xs: 20, md: 40 },
            left: "auto",
          }}
          size="large"
          endIcon={<AddCircle />}
        >
          報告作成
        </Button>
      )}
    </GenericTemplate>
  );
};

// 件数ボタン -----------------------------------------------------
interface IDashboardCard extends IDashboardInfo {
  type: string;
  onClick: Function;
  disabled?: boolean;
}
const DashboardCard = (data: IDashboardCard) => {
  return (
    <Card
      sx={{ cursor: data.disabled ? "default" : "pointer" }}
      onClick={() => {
        if (!data.disabled) data.onClick();
      }}
    >
      <CardContent
        sx={{ bgcolor: data.disabled ? Colors.DISABLE_INPUT_BG : undefined }}
      >
        <Typography variant="body2" component="div">
          {data.title}
        </Typography>
        <Typography
          sx={{
            mt: 1.5,
            textAlign: "center",
            borderRadius: "50%",
            border: "ButtonText",
            borderWidth: 2,
          }}
          color={
            data.count === 0
              ? undefined
              : data.type === StatisticalBoardType.request
              ? Colors.MAIN_GREEN
              : Colors.SECONDARY
          }
          variant="h5"
        >
          <span className="IgnoreExtractRuleTarget">{data.count}</span>
        </Typography>
      </CardContent>
    </Card>
  );
};

export default DashboardScreen;
