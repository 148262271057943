import React, { Fragment, useState } from "react";
import { Box, Button, InputLabel, Paper } from "@mui/material";
import Dropzone from "components/atoms/Dropzone";
import CancelBtnBadge from "components/atoms/CancelBtnBadge";
import ModalController from "@shared-components/modal/ModalController";
import messages from "config/messages";
import { createObjectURL } from "@utils/index";
import { StateFormType } from "@utils/template/loadingReport";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";

const imageDefault: Type.ImageInfoType = {
  type: "",
  uri: "",
  name: "",
  path_file: "",
};

interface CustomImagePickerProps {
  title: string;
  props: [StateFormType, Function, Function];
  maxImage: number;
  imageArrayName: keyof StateFormType;
}

const CustomImagePicker = ({
  title,
  props,
  maxImage,
  imageArrayName,
}: CustomImagePickerProps) => {
  const [stateForm, setStateForm, uploadImage] = props;

  const handleAddImageItem = () => {
    if (stateForm?.[imageArrayName].length < maxImage) {
      setStateForm((prevState: any) => {
        const new_image_array = [...prevState[imageArrayName], imageDefault];
        return { ...prevState, [imageArrayName]: new_image_array };
      });
    }
  };

  const onChangeImage = async (
    index: number,
    imageInfo: Type.ImageInfoType,
  ) => {
    const newStateForm: StateFormType = { ...stateForm };
    newStateForm[imageArrayName][index] = imageInfo;
    setStateForm(newStateForm);
    if (imageInfo.uri) {
      uploadImage(imageArrayName, imageInfo);
    }
  };

  const handleDeleteImageItem = (index: number) => {
    if (index >= 0) {
      const newStateForm: StateFormType = { ...stateForm };
      newStateForm[imageArrayName].splice(index, 1);
      setStateForm(newStateForm);
    }
  };

  // 画像の位置移動
  const handelMovePositionUp = (imageIndex: number) => {
    if (imageIndex === 0) return;
    // 非活性ボタンを押下したときは早期リターン
    const newImages = [...stateForm[imageArrayName]];
    [newImages[imageIndex - 1], newImages[imageIndex]] = [
      newImages[imageIndex],
      newImages[imageIndex - 1],
    ];
    setStateForm((prevState: any) => ({
      ...prevState,
      [imageArrayName]: newImages,
    }));
  };

  const handleMovePositionDown = (imageIndex: number) => {
    if (imageIndex === stateForm[imageArrayName].length - 1) return;
    // 非活性ボタンを押下したときは早期リターン
    const newImages = [...stateForm[imageArrayName]];
    [newImages[imageIndex + 1], newImages[imageIndex]] = [
      newImages[imageIndex],
      newImages[imageIndex + 1],
    ];
    setStateForm((prevState: any) => ({
      ...prevState,
      [imageArrayName]: newImages,
    }));
  };

  return (
    <>
      {stateForm?.[imageArrayName].map((item: any, index: number) => (
        <Fragment key={index}>
          <CancelBtnBadge
            onClick={() => {
              if (item.uri !== "") {
                onChangeImage(index, imageDefault);
              } else {
                ModalController.show({
                  message: "報告内容を削除しますか?",
                  visibleButton1: true,
                  visibleButton2: true,
                  button1: { title: messages.COMMON.BUTTON.CANCEL },
                  handlePressButton2: () => handleDeleteImageItem(index),
                });
              }
            }}
            key={index}
            sx={{ width: "100%" }}
            componentsProps={{ badge: { style: { right: "4%", top: "4%" } } }}
          >
            <Paper variant="outlined" sx={{ width: "100%", p: 1 }} key={index}>
              <Box sx={{ mb: 1, p: 0 }}>
                <InputLabel>
                  {String.fromCharCode(9311 + (index + 1)) + title}
                </InputLabel>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {item.uri !== "" ? (
                  <Box
                    component="img"
                    src={
                      item?.type === "image/svg+xml"
                        ? item?.non_Local
                          ? item.uri_jpg
                          : item.uriThumb
                        : item?.uri
                    }
                    sx={{
                      height: 140,
                      width: "80%",
                      objectFit: "cover",
                      borderRadius: 1,
                    }}
                  />
                ) : (
                  <Fragment key={index}>
                    <Box width={"80%"}>
                      <Dropzone
                        onChoose={(res) => {
                          const uri = createObjectURL(res[0]);
                          res[0]["uri"] = uri;
                          onChangeImage(index, res[0]);
                        }}
                        maxFiles={1}
                        showFileList={false}
                        key={index}
                      />
                    </Box>
                  </Fragment>
                )}
                <Box
                  sx={{
                    width: "20%",
                    height: 140,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  <Button
                    disabled={index === 0}
                    onClick={() => handelMovePositionUp(index)}
                    sx={{
                      minWidth: "30px",
                      p: "6px",
                    }}
                  >
                    <ArrowDropUp />
                  </Button>
                  <Button
                    disabled={index + 1 === stateForm?.[imageArrayName].length}
                    onClick={() => handleMovePositionDown(index)}
                    sx={{
                      minWidth: "30px",
                      p: "6px",
                    }}
                  >
                    <ArrowDropDown />
                  </Button>
                </Box>
              </Box>
            </Paper>
          </CancelBtnBadge>
        </Fragment>
      ))}
      <Button
        onClick={handleAddImageItem}
        disabled={stateForm?.[imageArrayName].length >= maxImage}
      >
        Add photos
      </Button>
    </>
  );
};

export default CustomImagePicker;
