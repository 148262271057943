import React, { useEffect, useMemo, useState, VFC } from "react";
import GenericTemplate from "@template/index";
import {
  Box,
  Button,
  IconButton,
  ListItemText,
  Tooltip,
  ListItem,
  Typography,
  MenuItem,
} from "@mui/material";
import {
  AddCircle,
  Delete,
  Edit,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import _ from "lodash";
import messages from "config/messages";
import ModalController from "@shared-components/modal/ModalController";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import { getListActivityBase } from "@api/groupMaster";
import {
  updateMasterSort,
  deleteMaster,
  getFuelAll,
  getListForkLiftCheckSection,
  getListForkLiftCheckItem,
} from "@api/itemReport";
import { insertMaster, updateMaster } from "@api/master";
import { ActivityBase, ForkliftItem } from "services/models";
import "./index.css";
import GroupEditDialog, { IData } from "./ForkliftItemEditDialog";
import TreeViewCustom from "components/atoms/TreeViewCustom";
import { LIST_FUEL, USER_ROLES } from "@shared-constants";
import SelectLabel from "components/atoms/SelectLabel";
import { getUserInfo } from "@utils/index";
import MasterExportButton from "components/organisms/MasterExportButton";
import { ID_CHECK_ITEM } from "shared/constants/MasterInfo";

interface IStateForm extends ForkliftItem {
  children?: IStateForm[];
  title?: string;
  isLeaf?: boolean;
  level?: number;
  loaded?: boolean;
}

type ILoopMatch = (
  item: IStateForm,
  isChild: boolean | undefined,
  check_child: { val: boolean },
  data: IStateForm[],
) => boolean;

type ILoopCallback = (
  item: IStateForm,
  index: number,
  data: IStateForm[],
  level: number,
  isChild: boolean | undefined,
) => any;

/**
 * ツリーデータループ処理
 */
const loop = (
  data: IStateForm[],
  isMatch: ILoopMatch,
  callback: ILoopCallback,
  level: number = 1,
  isChild?: boolean,
) => {
  for (let index = 0; index < data.length; index++) {
    let item = data[index];
    let check_child = { val: false };
    if (isMatch(item, isChild, check_child, data)) {
      callback(item, index, data, level, isChild);
      return;
    }
    if (item.children) {
      loop(item.children, isMatch, callback, level + 1, check_child.val);
    }
  }
};

const ForkliftItemScreen: VFC = () => {
  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------
  const [stateForm, setStateForm] = useState<IStateForm[]>([]);
  const [originData, setOriginData] = useState<IStateForm[]>([]);
  const [listActivityBase, setListActivityBase] = useState<ActivityBase[]>([]);
  const [listFuel, setListFuel] = useState<any[]>([]);
  const [userRole, setUserRole] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [editData, setEditData] = useState<IData>({
    SK: "",
    type: "",
    name: "",
    check_section_id: "",
  });
  const [isCreateMode, setIsCreateMode] = useState<boolean>(false);
  const [selectedGroup, setSelectedGroup] = useState<IStateForm | undefined>();
  const [activityBaseId, setActivityBaseId] = useState<string>("");
  const [selectDisabled, setSelectDisabled] = useState<boolean>(true);
  const [fuelId, setFuelId] = useState<string>("");
  const [selectFuelDisabled, setSelectFuelDisabled] = useState<boolean>(true);
  const [isDafaultFuel, setIsdafaultFuel] = useState<boolean>(false);
  const treeRef = React.useRef<any>(null);
  const [loadDataFlg, setLoadDataFlg] = useState(false);
  const [loadedKeys, setLoadedKeys] = useState<string[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);

  const maxLevel = 2;
  const type_section = "check_section";
  const type_create_section = "CHECK_SECTION";
  const type_create_item = "CHECK_ITEM";
  const type_item = "check_item";

  useEffect(() => {
    getFuel();
    getActivityBase();
  }, []);

  useEffect(() => {
    if (!selectFuelDisabled && !selectDisabled) {
      fetchData();
    } else {
      setStateForm([]);
      setOriginData([]);
      setExpandedKeys([]);
      setLoadedKeys([]);
    }
  }, [selectDisabled, selectFuelDisabled]);

  // データ取得 ------------------------------------------------
  const fetchData = async (sectionId?: string) => {
    try {
      if (activityBaseId && fuelId) {
        LoadingOverlayController.show();
        let isOpen = sectionId === undefined;
        let isLevel1 = sectionId ? false : true;
        let max_level = maxLevel;
        let res: ForkliftItem[] = [];

        if (isOpen) {
          isLevel1 = true;
        }
        if (sectionId) {
          // 点検項目を取得
          const res_item = await getListForkLiftCheckItem(
            activityBaseId,
            sectionId,
            fuelId,
          );
          res = res_item?.data;
        } else {
          // 点検箇所を取得
          const res_section = await getListForkLiftCheckSection(
            activityBaseId,
            fuelId,
          );
          res = res_section?.data;
        }

        if (res.length > 0) {
          if (isOpen) {
            const value = setInitialDataArr(res, max_level === 1, 1);
            setStateForm(JSON.parse(JSON.stringify(value)));
            setOriginData(JSON.parse(JSON.stringify(value)));
          } else {
            let new_data: IStateForm[] = JSON.parse(JSON.stringify(stateForm));
            let new_oData: IStateForm[] = JSON.parse(
              JSON.stringify(originData),
            );
            const callback: ILoopCallback = (item, index, data, level) => {
              const value = setInitialDataArr(
                res,
                maxLevel === level + 1,
                level + 1,
              );

              // 取得済みの下階層データで上書き
              if (item.children) {
                item.children.forEach((c) => {
                  if (c.children) {
                    const tmp_value = value.find((v) => v.SK === c.SK);

                    if (tmp_value) {
                      tmp_value.children = JSON.parse(
                        JSON.stringify(c.children),
                      );
                    }
                  }
                });
              }
              item.children = value;
              item.isLeaf = value.length > 0 ? false : true;
            };

            //　表示データに取得したグループを追加
            if (isLevel1) {
              new_data = [];
              new_oData = [];
              res.forEach((value: IStateForm) => {
                const d = stateForm.find((r) => r.SK === value.SK);
                const d_o = originData.find((r) => r.SK === value.SK);

                if (d && d_o) {
                  // 取得済みの下階層データで上書き
                  value.children = d.children;
                  setInitialData(value, d.isLeaf, d.level);

                  const index = stateForm.indexOf(d);
                  const index_o = originData.indexOf(d_o);
                  new_data.splice(index, 0, JSON.parse(JSON.stringify(value)));
                  new_oData.splice(
                    index_o,
                    0,
                    JSON.parse(JSON.stringify(value)),
                  );
                } else {
                  setInitialData(value, max_level === 1, 1);
                  new_data.push(JSON.parse(JSON.stringify(value)));
                  new_oData.push(JSON.parse(JSON.stringify(value)));
                }
              });
            } else {
              loop(new_data, (item) => item.SK === sectionId, callback, 1);
              loop(new_oData, (item) => item.SK === sectionId, callback, 1);
            }
            setStateForm(new_data);
            setOriginData(new_oData);
          }
        }
        return res;
      }
    } catch (error: any) {
      console.log("error fetchData", error);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  /**
   * 初期データ一括設定
   */
  const setInitialDataArr = (
    data: IStateForm[],
    isLeaf?: boolean,
    level?: number,
  ) => {
    const initial_data: IStateForm[] = [];
    data.forEach((item) => {
      const new_item = Object.assign({}, item);
      setInitialData(new_item, isLeaf, level);
      initial_data.push(new_item);
    });
    return initial_data;
  };

  /**
   * 初期データ設定
   */
  const setInitialData = (
    data: IStateForm,
    isLeaf?: boolean,
    level?: number,
  ) => {
    data.title = "";
    data.isLeaf = isLeaf;
    data.level = level;
    data.loaded = true;
  };

  const user_info = getUserInfo();

  // 拠点情報取得
  const getActivityBase = async () => {
    setUserRole(user_info.user_role);
    if (user_info.user_role == USER_ROLES.OWNER.value) {
      await getListActivityBase()
        .then((res) => {
          if (res) {
            setListActivityBase(res);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setActivityBaseId(user_info.location_id);
      setSelectDisabled(false);
    }
  };

  // 燃料情報取得
  const getFuel = async () => {
    try {
      LoadingOverlayController.show();
      const res = await getFuelAll();
      if (res.data.length > 0) {
        setListFuel(res.data);
      } else {
        setListFuel(LIST_FUEL);
        setIsdafaultFuel(true);
      }
    } catch (error: any) {
      console.log("error getActivityBase", error);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  // ツリービュー設定 ------------------------------------------------
  const fieldNames = {
    key: "SK",
  };

  // 開閉アイコン
  const switcherIcon = (obj: any) => {
    if (obj.isLeaf) {
      return <Box sx={{ width: "24px" }} />;
    }
    return obj.expanded ? <ExpandLess /> : <ExpandMore />;
  };

  const titleRender = (node: IStateForm) => {
    const name = node.check_section_name
      ? node.check_section_name
      : node.check_item_name;
    return (
      <ListItem sx={{ py: 0 }}>
        <ListItemText
          primaryTypographyProps={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <span className="IgnoreExtractRuleTarget">{name}</span>
        </ListItemText>

        <Box sx={{ flexGrow: 1 }} />
        {node.level && node.level < maxLevel && (
          <Tooltip title="追加">
            <IconButton sx={{ mr: 1 }} onClick={handleAdd(node)}>
              <AddCircle />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title="編集">
          <IconButton sx={{ mr: 1 }} onClick={handleEdit(node)}>
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip title="削除">
          <IconButton onClick={handleDelete(node)}>
            <Delete />
          </IconButton>
        </Tooltip>
      </ListItem>
    );
  };

  // データ読み込み
  const onLoadData = (treeNode: any) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        // StrictModeの2回発火を回避
        if (!treeRef.current) {
          treeRef.current = treeNode;
          setLoadDataFlg(true);
          let key_arr = loadedKeys;
          key_arr.push(treeNode.SK);
          setLoadedKeys(key_arr);
        }
        resolve(undefined);
      }, 500);
    });
  };

  useEffect(() => {
    if (loadDataFlg && treeRef.current) {
      fetchData(treeRef.current.SK).then(() => {
        setLoadDataFlg(false);
        treeRef.current = null;
      });
    }
  }, [treeRef.current, loadDataFlg]);

  // 入力制御 ------------------------------------------------
  const hasUpdateData = useMemo(() => {
    return stateForm && originData && !_.isEqual(stateForm, originData);
  }, [stateForm, originData]);

  // ドラッグ & ドロップ ---------------------------------------
  const onDrop = (info: any) => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const drag_level = info.dragNode.level;
    const drop_level = info.node.level;
    let dragObj: any;
    const new_stateForm = JSON.parse(JSON.stringify(stateForm));

    // 移動データを検索、削除
    loop(
      new_stateForm,
      (item) => item.SK === dragKey,
      (item, index, data) => {
        if (data.length === 1) {
          return;
        }
        data.splice(index, 1);
        dragObj = item;
      },
    );

    if (!dragObj) return;

    // 移動データを挿入
    loop(
      new_stateForm,
      (item, isChild, check_child) => {
        check_child.val = item.SK === dropKey;
        return isChild || (item.SK === dropKey && drag_level === drop_level);
      },
      (item, index, data, level, isChild) => {
        if (isChild) {
          data.splice(index, 0, dragObj);
        } else {
          data.splice(index + 1, 0, dragObj);
        }
      },
    );

    setStateForm(new_stateForm);
  };

  // 入力ダイアログ -------------------------------------------
  const handleUpdateDialog = async (value: IData) => {
    if (editData.name === value.name) return;
    const formData = {
      type: value.type,
      name: value.name,
      color: "",
      color_id: "",
      color_name: "",
      index: "",
      parent: "",
      activity_base_id: activityBaseId,
      is_manual_input: false,
      check_section_id: value.check_section_id,
      fuel_id: fuelId,
    };
    const message =
      value.type === type_create_section ? "点検箇所" : "点検項目";
    try {
      LoadingOverlayController.show();
      let res = undefined;
      if (isCreateMode) {
        // 追加
        res = await createItem(formData, message);
      } else {
        // 編集
        res = await updateItem(formData, value.SK, message);
      }
    } catch (error: any) {
      console.log("error", error);
      ModalController.show({
        message: error && error?.detail,
        visibleButton2: true,
      });
    } finally {
      setSelectedGroup(undefined);
      LoadingOverlayController.hide();
    }
  };

  // 追加 ------------------------------------------------
  const handleAdd = (data?: IStateForm) => (e: React.MouseEvent) => {
    e.stopPropagation();
    setSelectedGroup(data ? JSON.parse(JSON.stringify(data)) : undefined);
    const type = data ? type_create_item : type_create_section;
    const check_section_id = data?.SK ?? "";
    setEditData({
      SK: "",
      type: type,
      name: "",
      check_section_id: check_section_id,
    });
    setIsCreateMode(true);
    setOpen(true);
  };

  const createItem = async (value: any, message: string) => {
    const section_id = selectedGroup ? selectedGroup.SK : "";
    const res = await insertMaster(value);
    if (res) {
      ModalController.show({
        message: messages.COMMON.MSG_COMMON_SUCCESS_001(message + "の作成"),
        visibleButton2: true,
        handlePressButton2: async () => {
          await fetchData(section_id);
        },
      });
    }
  };

  // 更新 ------------------------------------------------
  const handleEdit = (data: IStateForm) => (e: React.MouseEvent) => {
    e.stopPropagation();
    if (data.check_item_name) {
      setSelectedGroup(JSON.parse(JSON.stringify(data)));
    }
    setEditData({
      SK: data.SK,
      type: data.check_section_name ? type_create_section : type_create_item,
      name: data.check_section_name
        ? data.check_section_name
        : data.check_item_name,
      check_section_id: data.check_section_name ? data.SK : "",
    });
    setIsCreateMode(false);
    setOpen(true);
  };

  const updateItem = async (value: any, SK: string, message: string) => {
    let res = undefined;
    const section_id = selectedGroup ? selectedGroup.check_section_id : "";

    res = await updateMaster(value, SK);

    if (res) {
      ModalController.show({
        message: messages.COMMON.MSG_COMMON_SUCCESS_001(message + "の更新"),
        visibleButton2: true,
        handlePressButton2: async () => {
          await fetchData(section_id);
        },
      });
    }
    return res;
  };

  // 削除 ------------------------------------------------
  const handleDelete = (data: IStateForm) => (e: React.MouseEvent) => {
    e.stopPropagation();
    ModalController.show({
      message: messages.COMMON.MSG_COMMON_DELETE_CONFIRM_001,
      visibleButton1: true,
      visibleButton2: true,
      handlePressButton2: async () => {
        await handleDeleteConfirm(JSON.parse(JSON.stringify(data)));
      },
    });
  };

  const handleDeleteConfirm = async (deleteData: IStateForm) => {
    try {
      LoadingOverlayController.show();

      let section_id = deleteData.check_item_name
        ? deleteData.check_section_id
        : "";

      let type = deleteData.check_section_name ? type_section : type_item;
      let name = deleteData.check_section_name
        ? deleteData.check_section_name
        : deleteData.check_item_name;
      const res = await deleteMaster(deleteData.SK, type, activityBaseId);
      if (res) {
        ModalController.show({
          message: messages.COMMON.MSG_COMMON_DELETE_SUCCESS_001(name),
          visibleButton2: true,
          handlePressButton2: async () => {
            await fetchData(section_id);
          },
        });
      }
    } catch (error: any) {
      console.log("error delete group", error);

      ModalController.show({
        message: error && error?.detail,
        visibleButton2: true,
      });
    } finally {
      setSelectedGroup(undefined);
      LoadingOverlayController.hide();
    }
  };

  // 並べ替え --------------------------------------------
  const handleSortUpdate = async () => {
    const loop_sort = async (
      data: IStateForm[],
      origin: IStateForm[],
      callback: Function,
    ) => {
      for (let index = 0; index < data.length; index++) {
        const item = data[index];
        const originItem = origin[index];
        //変更チェック
        if (!_.isEqual(item, originItem)) {
          await callback(item, data);
        }
        if (
          item.children &&
          originItem.children &&
          !_.isEqual(item.children, originItem.children)
        ) {
          //変更チェック
          await loop_sort(item.children, originItem.children, callback);
        }
      }
    };

    try {
      LoadingOverlayController.show();
      let dataUpload: { PK: string; SK: string }[] = [];
      let listUploadedID: string[] = [];
      let res: any = undefined;
      let message = "";
      await loop_sort(
        stateForm,
        originData,
        async (item: IStateForm, data: IStateForm[]) => {
          if (listUploadedID.indexOf(item.SK) === -1) {
            listUploadedID.push(item.SK);
            dataUpload = [];
            data.forEach((item) => {
              dataUpload.push({ PK: item.PK, SK: item.SK });
            });
            const type = data[0].check_section_name ? type_section : type_item;

            if (dataUpload.length > 1)
              res = await updateMasterSort(type, dataUpload);
            message = data[0].check_section_name
              ? "点検箇所の並べ替え"
              : "点検項目の並べ替え";
          }
        },
      );
      if (res?.data) {
        ModalController.show({
          message: messages.COMMON.MSG_COMMON_SUCCESS_001(message),
          visibleButton2: true,
          handlePressButton2: () => {
            setOriginData(JSON.parse(JSON.stringify(stateForm)));
          },
        });
      }
    } catch (error: any) {
      console.log("error handleSortUpdate", error);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  return (
    <GenericTemplate title="点検箇所・点検項目管理">
      <GroupEditDialog
        data={editData}
        open={open}
        setOpen={setOpen}
        handleButtonOK={handleUpdateDialog}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          mt: 3,
          mb: 3,
        }}
      >
        <SelectLabel
          label="燃料"
          value={fuelId}
          isIgnoreExtractRuleLabel={false}
          isIgnoreExtractRuleSelect={true}
          onChange={(e) => setFuelId(e.target.value)}
          sx={{ width: 500 }}
          disabled={!selectFuelDisabled}
        >
          {listFuel.map((item: any) =>
            isDafaultFuel ? (
              <MenuItem value={item.SK} key={item.SK}>
                {item.fuel_name}
              </MenuItem>
            ) : (
              <MenuItem value={item.SK} key={item.SK}>
                <span className="IgnoreExtractRuleTarget">
                  {item.fuel_name}
                </span>
              </MenuItem>
            ),
          )}
        </SelectLabel>
        <Button
          onClick={() => {
            setSelectFuelDisabled(!selectFuelDisabled);
          }}
          disabled={!fuelId}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            mt: 3,
            ml: 2,
          }}
        >
          {selectFuelDisabled ? "選択" : "解除"}
        </Button>
      </Box>
      {userRole == USER_ROLES.OWNER.value && (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              mt: 3,
              mb: 3,
            }}
          >
            <SelectLabel
              label="拠点"
              value={activityBaseId}
              isIgnoreExtractRuleLabel={false}
              isIgnoreExtractRuleSelect={true}
              onChange={(e) => setActivityBaseId(e.target.value)}
              sx={{ width: 500 }}
              disabled={!selectDisabled}
            >
              {listActivityBase.map((item: any) => (
                <MenuItem value={item.SK} key={item.SK}>
                  <span className="IgnoreExtractRuleTarget">
                    {item.activity_base_name}
                  </span>
                </MenuItem>
              ))}
            </SelectLabel>
            <Button
              onClick={() => {
                setSelectDisabled(!selectDisabled);
              }}
              disabled={!activityBaseId}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mt: 3,
                ml: 2,
              }}
            >
              {selectDisabled ? "選択" : "解除"}
            </Button>
          </Box>
        </>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 1,
        }}>
        <Typography variant="body2">ドラッグ＆ドロップで並べ替え</Typography>
        <Box sx={{ flexGrow: 1 }} />
        <MasterExportButton
          masterType={ID_CHECK_ITEM}
          activityBaseId={activityBaseId}
          userInfo={user_info}
          isDisabled={selectDisabled || selectFuelDisabled}
        />
        <Button
          endIcon={<AddCircle />}
          onClick={handleAdd()}
          disabled={selectDisabled || selectFuelDisabled}
        >
          点検箇所追加
        </Button>
      </Box>

      <TreeViewCustom
        prefixCls="rc-tree-group-info"
        fieldNames={fieldNames}
        draggable
        style={{
          padding: "8px 0px",
          marginBottom: "30px",
        }}
        treeData={stateForm}
        onDrop={onDrop}
        dropIndicatorRender={() => <></>}
        showIcon={false}
        switcherIcon={switcherIcon}
        loadData={onLoadData}
        titleRender={titleRender}
        allowDrop={({ dragNode, dropNode }) => {
          // 自階層内のみ並べ替え可
          if (
            dragNode.level === dropNode.level &&
            dragNode.check_section_id === dropNode.check_section_id
          )
            return true;

          if (
            Number(dragNode.level) - 1 === Number(dropNode.level) &&
            dragNode.check_section_id === dropNode.SK
          )
            return true;
          return false;
        }}
        selectable={false}
        expandAction="click"
        loadedKeys={loadedKeys}
        expandedKeys={expandedKeys}
        onExpand={(expanded_key) => {
          // データ切替時の開閉
          let expand_arr: string[] = [];
          if (expanded_key.length > 0) {
            expanded_key.map((item: any) => {
              expand_arr.push(String(item));
            });
          }
          setExpandedKeys(expand_arr);
        }}
      />

      <Box
        position={"fixed"}
        sx={{ m: 0, right: 0, bottom: 20, textAlign: "center", width: "100%" }}
      >
        <Button
          onClick={handleSortUpdate}
          color="secondary"
          disabled={!hasUpdateData}
        >
          保存
        </Button>
      </Box>
    </GenericTemplate>
  );
};

export default ForkliftItemScreen;
