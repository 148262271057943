import { useCallback, useEffect, useMemo, useRef, useState, VFC } from "react";
import { Button, Grid, Paper, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { DeleteOutline } from "@mui/icons-material";
import GenericTemplate from "@template/index";
import PopupMenu from "components/atoms/PopupMenu";
import PopupSort from "components/atoms/PopupSort";
import {
  HeadCell,
  TableButton,
  TableInfiniteScroll,
} from "components/organisms/TableInfiniteScroll";
import FilterInput from "components/molecules/FilterInput";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import { LIST_TYPE_SORT_CUSTOM_TEMPLATE, USER_ROLES } from "@shared-constants";
import { useHistoryCustom } from "shared/hook/useHistoryCustom";
import useDeepCompareEffect from "shared/hook/useDeepCompareEffect";
import { TYPES } from "store/types";
import messages from "config/messages";
import { getListProjectUserMade, getListUserCompanyApi } from "@api/project";
import { getListActivityBase } from "@api/groupMaster";
import {
  getConditionFilter,
  getHasFilterConditionProject,
} from "selector/filterSelector";
import { getSortOfScreen } from "selector/sortSelector";
import {
  getParamsFilter,
  getUserInfo,
  isAuthorizedViewCustomTemplate,
  isOpenProject,
} from "@utils/index";
import ModalCopyCustomTemplateConfirm from "components/molecules/ModalCopyCustomTemplateConfirm";

let paramsFilter: any;

const headCells: HeadCell[] = [
  {
    id: "is_corp_standard_disp",
    label: "全社",
    width: 60,
    isIgnoreExtractRuleCell: true,
  },
  {
    id: "report_code_name",
    label: "帳票分類",
    width: 155,
    isIgnoreExtractRuleCell: true,
  },
  {
    id: "project_name",
    label: "帳票名",
    width: 155,
    isIgnoreExtractRuleCell: true,
  },
  {
    id: "activity_base_name",
    label: "拠点",
    width: 100,
    isIgnoreExtractRuleCell: true,
  },
  {
    id: "created_user_name",
    label: "作成者",
    width: 110,
    isIgnoreExtractRuleCell: true,
  },
  {
    id: "updated_user_name",
    label: "更新者",
    width: 110,
    isIgnoreExtractRuleCell: true,
  },
  {
    id: "open_state_disp",
    label: "公開状態",
    width: 110,
    isIgnoreExtractRuleCell: true,
  },
  {
    id: "last_used_at",
    label: "最終利用日時",
    width: 140,
    disablePadding: true,
    isIgnoreExtractRuleCell: true,
    format: "YMDHms_sl",
  },
  {
    id: "updated_at",
    label: "更新日時",
    width: 140,
    disablePadding: true,
    isIgnoreExtractRuleCell: true,
    format: "YMDHms_sl",
  },
  {
    id: "detail",
    label: "",
    width: 65,
    isIgnoreExtractRuleCell: true,
    disablePadding: true,
  },
  {
    id: "copy",
    label: "",
    width: 65,
    isIgnoreExtractRuleCell: true,
    disablePadding: true,
  },
];

const CustomTemplateListScreen: VFC = () => {
  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------
  const [data, setData] = useState<any[]>([]);
  const [lastKey, setLastKey] = useState<any | null>(null);
  const [loadMore, setLoadMore] = useState<boolean>(true);
  const [sortBy, setSortBy] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [buttonList, setButtonList] = useState<TableButton[]>([]);

  const TYPE_SCREEN = "custom_template";
  const hasCondition = useSelector(getHasFilterConditionProject(TYPE_SCREEN));
  const templateFilter = useSelector(getConditionFilter(TYPE_SCREEN));
  const typeSort = useSelector(getSortOfScreen(TYPE_SCREEN));
  const [searchedKeyword, setSearchKeyword] = useState<string>("");
  const [isDisableFilter, setIsDisableFilter] = useState<boolean>(false);

  const [openCopyMaster, setOpenCopyMaster] = useState<boolean>(false);
  const [copyData, setCopyData] = useState<any>();

  const refListGroup = useRef<any[]>();
  const refListUser = useRef<any[]>();

  const user_info = getUserInfo();

  const history = useHistoryCustom();
  const dispatch = useDispatch();

  // ------------------------------------------------------------------
  // データ取得
  // ------------------------------------------------------------------
  const fetchListTemplate = async (
    keyWord?: string,
    sortType?: string,
    lastKeyword?: string,
    sort_by?: string,
    isMerge: boolean = false,
  ) => {
    setLoading(true);
    if (data.length === 0) {
      LoadingOverlayController.show();
    }

    // マスタ情報取得
    if (!refListGroup.current) {
      await getListGroup();
    }
    if (!refListUser.current) {
      await getListUser();
    }

    const params = {
      keyword: keyWord ?? searchedKeyword,
      sort_type: sortType ?? typeSort,
      last_key: lastKeyword ?? lastKey,
      sort_by: sort_by ?? sortBy ?? undefined,
      filter_condition: paramsFilter,
    };

    return await getListProjectUserMade(params)
      .then((res) => {
        const res_data = addDisplayData(res?.data);
        if (isMerge) {
          setData((preValue) => [...preValue, ...res_data]);
        } else {
          setData(res_data);
        }
        if (res?.last_key) {
          setLastKey(res?.last_key?.SK ?? null);
          setSortBy(res?.last_key?.sort_by ?? null);
          setLoadMore(true);
        } else {
          setLastKey(null);
          setSortBy(null);
          setLoadMore(false);
        }
        if (res?.data.length < 10 && res?.last_key) {
          fetchListTemplate(
            params.keyword,
            params?.sort_type,
            res?.last_key?.SK,
            res?.last_key?.sort_by,
            true,
          );
        }
        if (!res?.last_key || (res.data.length >= 10 && res.last_key)) {
          LoadingOverlayController.hide();
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading(false);
        LoadingOverlayController.hide();
      });
  };

  const getListGroup = useCallback(async () => {
    await getListActivityBase()
      .then((res) => {
        if (res) {
          refListGroup.current = res;
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [refListGroup]);

  const getListUser = useCallback(async () => {
    const params = {
      keyword: "",
      location_id: "",
      ignore_me: false,
    };
    await getListUserCompanyApi(params)
      .then((res) => {
        if (res?.data) {
          refListUser.current = res.data;
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [refListUser]);

  const getActivityBaseName = useCallback(
    (location_id?: string) => {
      if (!location_id || !refListGroup.current) return "";
      const group = refListGroup.current.find((item) => item.SK == location_id);
      if (group) {
        return group.activity_base_name;
      } else {
        return "";
      }
    },
    [refListGroup],
  );

  const getUserFullName = useCallback(
    (user_id?: string) => {
      if (!user_id || !refListUser.current) return "";
      const user = refListUser.current.find(
        (item) => item.SK == user_id || item.SK == "USER#" + user_id,
      );
      if (user) {
        return user.full_name;
      } else {
        return "";
      }
    },
    [refListUser],
  );

  const addDisplayData = useCallback(
    (data?: any[]) => {
      if (!data) return [];

      var response: any[] = [];

      data.forEach((item) => {
        // 公開判定
        const open_state = isOpenProject(
          item.open_state,
          item.project_start_date,
          item.project_end_date,
        );
        // 参照可否
        if (
          isAuthorizedViewCustomTemplate(
            user_info.user_role,
            open_state,
            user_info.location_id,
            item.project_location_id,
          )
        ) {
          response.push({
            ...item,
            is_corp_standard_disp: item.is_corp_standard ? "○" : "",
            report_code_name:
              item.report_type_code +
              item.report_number +
              ":" +
              item.report_name,
            activity_base_name: getActivityBaseName(item.project_location_id),
            created_user_name: getUserFullName(item.created_by),
            updated_user_name: getUserFullName(item.updated_by),
            open_state_disp: open_state ? "公開" : "非公開",
          });
        }
      });
      return response;
    },
    [getActivityBaseName, getUserFullName],
  );

  useEffect(() => {
    const list_button: TableButton[] = [];
    data.forEach((d) => {
      list_button.push({
        cell_id: "detail",
        button: (
          <Button
            size="small"
            sx={{ minWidth: "60px" }}
            onClick={handleClickDetail(d)}
          >
            詳細
          </Button>
        ),
        id: d.SK,
      });
      list_button.push({
        cell_id: "copy",
        button: (
          <Button
            size="small"
            sx={{ minWidth: "60px" }}
            onClick={handleClickCopy(d)}
          >
            複製
          </Button>
        ),
        id: d.SK,
      });
    });
    setButtonList(list_button);
  }, [data]);

  useEffect(() => {
    if (templateFilter) {
      paramsFilter = getParamsFilter(templateFilter);
    }
    fetchListTemplate();
  }, []);

  const handleClickDetail = (data: any) => () => {
    dispatch({
      type: TYPES.SET_SELECTED_PROJECT_ID,
      selected_project_id: data.SK,
      selected_project_name: data.project_name,
    });
    history.pushWithRef("/project/info", {
      typeAction: "custom",
      project_id: data.SK,
    });
  };

  const handleClickCopy = (data: any) => () => {
    setCopyData(data);
    setOpenCopyMaster(true);
  };

  // ------------------------------------------------------------------
  // 絞り込み
  // ------------------------------------------------------------------
  useEffect(() => {
    if (!data.length && !hasCondition) {
      setIsDisableFilter(true);
    } else {
      setIsDisableFilter(false);
    }
  }, [data]);

  useDeepCompareEffect(() => {
    if (sortBy) {
      setSortBy(null);
    }
    if (templateFilter) {
      paramsFilter = getParamsFilter(templateFilter);
    }
  }, [templateFilter]);

  // ------------------------------------------------------------------
  // 並べ替え
  // ------------------------------------------------------------------
  const handleSort = (type_sort: string) => {
    LoadingOverlayController.show();
    setSortBy(type_sort);
    const payload = {
      type_screen: TYPE_SCREEN,
      value: type_sort,
    };
    dispatch({ type: TYPES.SET_SORT_SCREEN, payload });
    dispatch({ type: TYPES.SET_SORT_CONDITION, payload });
    fetchListTemplate(undefined, type_sort, "", "");
  };

  const sortDefaultIndex = useMemo(
    () => {
      const defaultSort = Object.values(LIST_TYPE_SORT_CUSTOM_TEMPLATE).filter(
        (item) => item.value === typeSort,
      );
      if (defaultSort.length === 0) {
        return 0;
      } else {
        return Object.values(LIST_TYPE_SORT_CUSTOM_TEMPLATE).indexOf(
          defaultSort[0],
        );
      }
    },
    [] /* 画面初期表示時のみ判定 */,
  );

  // ------------------------------------------------------------------
  // 検索
  // ------------------------------------------------------------------
  const _handleSearch = (str: string) => {
    setSearchKeyword(str);
    setSortBy(null);
    LoadingOverlayController.show();
    fetchListTemplate(str, undefined, "", "");
  };

  const showLoadMore = useMemo(() => {
    return loadMore && lastKey && data.length > 0;
  }, [data, loadMore, lastKey]);

  return (
    <GenericTemplate
      title={"ユーザー作成帳票一覧"}
      isSearch={true}
      searchPlaceholder="帳票名で検索"
      onSearch={(v) => _handleSearch(v)}
      onSearchClear={() => _handleSearch("")}
    >
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} md="auto">
          {/* =================== メニュー =================== */}
          <PopupMenu
            data={[
              {
                name: "ゴミ箱",
                onClick: () => {
                  history.pushWithRef("/custom-template/trash");
                },
                icon: <DeleteOutline />,
                disabled:
                  user_info.user_role === USER_ROLES.MEMBER.value ||
                  user_info.user_role === USER_ROLES.EDITOR.value,
              },
            ]}
          />
          {/* =================== 並べ替え =================== */}
          <PopupSort
            data={Object.values(LIST_TYPE_SORT_CUSTOM_TEMPLATE).map((item) => {
              return {
                title: item.title,
                onClick: () => handleSort(item.value),
              };
            })}
            defaultIndex={sortDefaultIndex}
            disabled={data.length === 0}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md="auto"
          sx={{ width: { md: "40%" }, marginLeft: "auto" }}
        >
          {/* =================== 絞り込み =================== */}
          <FilterInput
            typeScreen={TYPE_SCREEN}
            disabled={isDisableFilter}
            onFetchData={() => {
              LoadingOverlayController.show();
              fetchListTemplate(undefined, undefined, "", "");
            }}
          />
        </Grid>
      </Grid>

      {/* =================== ユーザー作成帳票 =================== */}
      <Paper elevation={0} sx={{ py: 1 }}>
        <TableInfiniteScroll
          rows={data}
          headCells={headCells}
          buttons={buttonList}
          idName="SK"
          maxHeight={"70vh"}
          showTooltip
          noWrap
          initialLoad={false}
          isLoading={loading}
          hasMore={showLoadMore}
          loadMore={() => {
            if (showLoadMore && !loading)
              fetchListTemplate(
                undefined,
                undefined,
                undefined,
                undefined,
                true,
              );
          }}
          message={
            data.length === 0 && !loading
              ? searchedKeyword === ""
                ? messages.COMMON.MSG_NOT_EXIST("ユーザー作成帳票")
                : messages.COMMON.MSG_NON_SEARCH_RESULT(searchedKeyword)
              : ""
          }
        />
      </Paper>

      <Button
        onClick={() => {
          history.pushWithRef("/project/create", { typeAction: "custom" });
        }}
        color="secondary"
        sx={{
          position: "fixed",
          margin: 0,
          top: "auto",
          right: { xs: 20, md: 40 },
          bottom: { xs: 20, md: 40 },
          left: "auto",
          zIndex: 3,
        }}
        size="large"
        disabled={user_info.user_role === USER_ROLES.MEMBER.value}
      >
        新規作成
      </Button>
      <ModalCopyCustomTemplateConfirm
        open={openCopyMaster}
        user_info={user_info}
        data={copyData}
      />
    </GenericTemplate>
  );
};

export default CustomTemplateListScreen;
