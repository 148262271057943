import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import { createConnector, METHOD_API } from "@api/Connector";
import { clearData, getDataStorage, STORAGE, storeData } from "@utils/Storage";
import ModalController from "@shared-components/modal/ModalController";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import { getUserInfo, updateToken } from "auth/Auth";
import { TYPES } from "store/types";
import {
  checkFilterConditionEmpty,
  checkHasChangeSortCondition,
  getConditionFromStore,
} from "@utils/index";
import { getListCategory } from "@api/category";
import { getListStatusProjectApi } from "@api/project";

/**
 * ログイン処理
 * @param action
 */
export function* login(action: any): any {
  LoadingOverlayController.show();
  const { ...props } = action.payload;

  try {
    // ログイン
    const res: AxiosResponse = yield call(
      createConnector,
      METHOD_API.POST,
      `api/v1/login-contract-split`,
      props,
    );
    if (res) {
      const resConvert = JSON.parse(JSON.stringify(res));

      // ユーザー情報を保存
      yield call(
        updateToken,
        resConvert.access_token,
        resConvert.refresh_token,
      );
      yield call(
        storeData,
        STORAGE.USER_INFO,
        JSON.stringify({ preferred_username_cognito: props.username_id }),
      );

      const userData = yield call(getUserInfo);
      if (!userData) {
        if (LoadingOverlayController.isShowing())
          LoadingOverlayController.hide();
        return;
      }

      // TLOG DEL --->>
      // yield put({ type: TYPES.SET_MASTER_COLOR_DATA });
      // TLOG DEL <<---
      yield put({
        type: TYPES.SET_AUTH,
        payload: { is_password_reset: resConvert.is_password_reset },
      });

      // フィルターを保存
      const filterStore = yield call(
        getConditionFromStore,
        "filter",
        userData?.user_id,
      );
      const isEmptyFilter: boolean = yield call(
        checkFilterConditionEmpty,
        filterStore,
      );
      if (!isEmptyFilter && filterStore) {
        // TLOG EDIT --->>
        // const categoryData = yield call(getListCategory);
        // const statusData = yield call(getListStatusProjectApi);
        // let newFilterStore = setFilterName(
        //   filterStore,
        //   categoryData.data,
        //   statusData?.data,
        // );
        let newFilterStore = setFilterName(filterStore, [], []);
        // TLOG EDIT <<---
        // set filter to redux
        yield put({
          type: TYPES.SET_ALL_FILTER_CONDITION,
          payload: newFilterStore,
        });
        // set filter to async storage
        yield put({ type: TYPES.SET_FILTER_CONDITION });
      }

      // ソートを保存
      const sortStore = yield call(
        getConditionFromStore,
        "sort",
        userData?.user_id,
      );
      const isChangeSortStore: boolean = yield call(
        checkHasChangeSortCondition,
        sortStore,
      );
      if (isChangeSortStore && sortStore) {
        yield put({
          type: TYPES.SET_SORT_ALL_SCREEN,
          payload: sortStore,
        });
      }

      // お気に入り帳票を保存
      const favoriteStore = yield call(
        getConditionFromStore,
        "favorite-project",
        userData?.user_id,
      );
      if (favoriteStore) {
        yield put({
          type: TYPES.SET_FAVORITE_PROJECT_INFO,
          payload: favoriteStore,
        });
      }

      // 拠点を保存
      const prev_location_cd = yield call(
        getDataStorage,
        STORAGE.LOCATION_CODE,
      );
      if (prev_location_cd !== props.location_code) {
        yield call(clearData, STORAGE.ALL_REPORT_ITEM);
        yield call(clearData, STORAGE.ALL_USER_MASTER_ITEM);
        yield call(clearData, STORAGE.ALL_INCIDENT_DAMAGE_ITEM);
      }
      yield call(storeData, STORAGE.LOCATION_CODE, props.location_code);

      // 2次元バーコードレイアウトを保存
      yield put({
        type: TYPES.SET_2D_BARCODE_LAYOUT,
        payload: { location_id: userData?.location_id },
      });

      // デバン検索条件を保存
      const devanSearchStore = yield call(
        getConditionFromStore,
        "devanSearch",
        userData?.user_id,
      );
      yield put({
        type: TYPES.SET_DEVAN_SEARCH,
        payload: devanSearchStore,
      });

      // サイン入力の線の太さを保存
      const signaturePenSizeStore = yield call(
        getConditionFromStore,
        "signaturePenSize",
        userData?.user_id,
      );
      yield put({
        type: TYPES.SET_EDIT_TEMPLATE,
        payload: signaturePenSizeStore,
      });

      // IncidentDamageReportのlastUsedを取得
      const incidentDamageReportStore = yield call(
        getConditionFromStore,
        "IncidentDamageReport",
        userData?.user_id,
      );
      yield put({
        type: TYPES.SET_INCIDENT_DAMAGE_REPORT,
        payload: incidentDamageReportStore,
      });

      // ログイン状況を変更
      yield put({ type: TYPES.SET_LOGGEDIN, payload: true });
    }
  } catch (error: any) {
    // console.log("error login");
    // console.log(error);
    ModalController.show({
      message: error.detail,
      visibleButton2: true,
    });

    // ログイン状況を変更
    yield put({ type: TYPES.SET_LOGGEDIN, payload: false });

    LoadingOverlayController.hide();
  }
}

/**
 * ログアウト処理
 * @param action
 */
export function* logout(action: any): any {
  try {
    // 保存情報を削除
    yield call(clearData, STORAGE.ACCESS_TOKEN);
    yield call(clearData, STORAGE.REFRESH_TOKEN);
    yield call(clearData, STORAGE.USER_INFO);
    yield call(clearData, STORAGE.COLOR_DATA);
    yield call(clearData, STORAGE.MAX_PRODUCT_ID);
    yield call(clearData, STORAGE.MAX_IMAGE_RISKY_REPORT);
    yield call(clearData, STORAGE.INCIDENT_DAMAGE_REPORT);
    yield put({ type: TYPES.CLEAR_AUTH });
    yield put({ type: TYPES.CLEAR_SORT_CONDITION });
    yield put({ type: TYPES.CLEAR_ALL_FILTER_CONDITION });
    yield put({ type: TYPES.CLEAR_FAVORITE_PROJECT_INFO });
    yield put({ type: TYPES.CLEAR_EDIT_TEMPLATE });
    yield put({ type: TYPES.CLEAR_INCIDENT_DAMAGE_REPORT });

    // ログイン状況を変更
    yield put({ type: TYPES.SET_LOGGEDIN, payload: false });
  } catch (error) {
    console.log(error);
  }
}

function setFilterName(FilterStore: any, categoryData: [], statusData: []) {
  const setCategoryName = (filter: any) => {
    for (let i = 0; i < filter.length; i++) {
      const index = categoryData.findIndex((d: any) => d.SK === filter[i].id);
      if (index >= 0) {
        filter[i].name = categoryData[index]["category_name"];
      }
    }
  };
  const setStatusName = (filter: any) => {
    for (let i = 0; i < filter.length; i++) {
      const index = statusData.findIndex((d: any) => d.SK === filter[i].id);
      if (index >= 0) {
        filter[i].name = statusData[index]["status_name"];
      }
    }
  };

  let response = { ...FilterStore };
  setCategoryName(response["folder"]["category"]);
  setCategoryName(response["work"]["category"]);
  setCategoryName(response["work_group"]["category"]);
  setCategoryName(response["work_delete"]["category"]);
  setCategoryName(response["work_copy"]["category"]);
  setStatusName(response["project"]["status"]);

  return response;
}
