import { createFormUpload } from "@utils/index";
import { insertLogApi } from "@api/log";

// ------------------------------------------------------------------
// ログの書き込み
// ------------------------------------------------------------------
export const insertExportLog = async (count: number, userInfo: any, masterType: string, activityBaseId: string) => {
  return insertLog(count, userInfo, masterType, activityBaseId, true);
}

const insertLog = async (
  count: number, userInfo: any, masterType: string, activityBaseId: string, isExport: boolean
) => {
  // TODO: Importにも対応させる
  const data = {
      export_account_id: userInfo.SK,
      export_account_name: userInfo.full_name,
      export_count: String(count),
      export_data_type: masterType,
      import_data_type: "",
      import_account_id: "",
      import_account_name: "",
      import_count: 0,
      is_export: isExport, // true→export, false→import
      activity_base_id: activityBaseId
  }
  const formData = createFormUpload(null, data);
  const res = await insertLogApi(formData);
  return res;
}