import { useEffect, useMemo, useState, VFC } from "react";
import GenericTemplate from "@template/index";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Tooltip,
  Button,
  Typography,
} from "@mui/material";
import {
  DraggableProvided,
  DroppableProvided,
  DropResult,
  Draggable,
  Droppable,
  DragDropContext,
} from "react-beautiful-dnd";
import { AddCircle, Delete, Edit } from "@mui/icons-material";
import { sort, getUserInfo } from "@utils/index";
import { insertMaster, updateMaster } from "@api/master";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import ModalController from "@shared-components/modal/ModalController";
import messages from "config/messages";
import { Colors } from "@template/style";
import { useLocation } from "react-router-dom";
import {
  deleteCustomMaster,
  getListCustomMaster,
  updateCustomMaster,
} from "@api/masterType";
import CustomMasterInfoEditDialog, {
  IData,
} from "./CustomMasterInfoEditDialog";
import InfiniteScroll from "react-infinite-scroller";
import ScrollLoader from "components/atoms/ScrollLoader";
import MasterExportButton from "components/organisms/MasterExportButton";

const initial_data: IData = {
  type: "名称",
  name: "",
  color: "",
  color_id: "",
  color_name: "",
  color_needs: false,
  is_manual_input: false,
  is_default: false,
  custom_item: [],
  custom_item_name: [],
};

const CustomMasterInfoScreen: VFC = () => {
  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------
  const [data, setData] = useState<any[]>([]);
  const [originData, setOriginData] = useState<any[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [editIndex, setEditIndex] = useState<number>(-1);
  const [editData, setEditData] = useState<IData>(initial_data);
  const location = useLocation<any>();
  const master_type_id = location.state?.SK;
  const master_name = location.state?.master_name ?? "";
  const item_name = location.state?.item_name ?? "name";
  const custom_item: Array<any> = location.state?.custom_item || [];
  const activityBaseId =
    master_type_id.indexOf("|") > -1
      ? master_type_id.split("|")[0]
      : "ACTIVITY_BASE#";
  const [lastKey, setLastKey] = useState<any | null>(null);
  const [loadMore, setLoadMore] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  const user_info = getUserInfo();

  useEffect(() => {
    fetchData(master_type_id);
  }, [master_type_id]);

  const handleDnd = (result: DropResult) => {
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }

    const sorted = sort(data, result.source.index, result.destination.index);
    setData(sorted);
  };

  const handleAdd = () => {
    setEditData({
      ...initial_data,
      custom_item: custom_item,
      custom_item_name: custom_item.map(() => ""),
    });
    setOpen(true);
  };

  const handleDelete = (SK: string) => {
    if (!ModalController.isShowing()) {
      const confirmMessage = disabled
        ? messages.COMMON.MSG_COMMON_DELETE_CONFIRM_001
        : messages.COMMON.MSG_COMMON_DELETE_CONFIRM_SORT_001;
      ModalController.show({
        message: confirmMessage,
        visibleButton1: true,
        visibleButton2: true,
        handlePressButton2: () => handleDeleteCustomMaster(SK),
      });
    }
    return;
  };

  const handleDeleteCustomMaster = async (SK: string) => {
    try {
      LoadingOverlayController.show();
      const res = await deleteCustomMaster(SK);
      if (res) {
        ModalController.show({
          message: messages.COMMON.MSG_COMMON_DELETE_SUCCESS_001(master_name),
          visibleButton2: true,
          handlePressButton2: () => {
            fetchData(master_type_id);
          },
        });
      }
    } catch (error: any) {
      if (!ModalController.isShowing())
        ModalController.show({
          message: error?.detail,
          visibleButton2: true,
        });
      console.log("error handleDeleteCustomMaster", error);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  const handleEdit = (d: any, i: number) => {
    const custom_item_name = custom_item?.map((value, index) => {
      if (value.barcode) {
        return d.barcode_item ?? "";
      } else {
        return d[`item${index + 1}`] ?? "";
      }
    });
    setEditData({
      type: "名称",
      name: d[item_name],
      color_id: d.color_id,
      color: d.color_code,
      color_name: d.color_name,
      color_needs: false,
      is_manual_input: d.is_manual_input,
      is_default: d.is_default,
      custom_item: custom_item,
      custom_item_name: custom_item_name,
    });
    setEditIndex(i);
    if (!ModalController.isShowing() && !disabled) {
      ModalController.show({
        message: messages.COMMON.MSG_COMMON_UPDATE_CONFIRM_SORT_001,
        visibleButton1: true,
        visibleButton2: true,
        handlePressButton2: () => {
          setOpen(true);
        },
      });
    } else {
      setOpen(true);
    }
  };

  const handleChange = async (value: IData) => {
    let newData = JSON.parse(JSON.stringify(data));
    let message = "";
    let res = null;
    const param_custom_item = custom_item.map((item, index) => ({
      ...item,
      name: value.custom_item_name[index],
    }));
    const formData = {
      type: "CUSTOM_MASTER",
      name: value.name,
      color: value.color,
      color_id: value.color_id,
      color_name: value.color_name,
      index: "",
      parent: "",
      activity_base_id: activityBaseId,
      report_type_code: "",
      is_manual_input: value.is_manual_input,
      is_default: value.is_default,
      master_type_id: master_type_id,
      custom_item: param_custom_item,
    };
    try {
      LoadingOverlayController.show();
      if (editIndex >= 0) {
        res = await updateMaster(formData, newData[editIndex].SK);
        if (res) {
          message = master_name + "の更新";
        }
      } else {
        res = await insertMaster(formData);
        if (res) {
          message = master_name + "の登録";
        }
      }
      setEditIndex(-1);
      if (res) {
        setData(newData);
        ModalController.show({
          message: messages.COMMON.MSG_COMMON_SUCCESS_001(message),
          visibleButton2: true,
          handlePressButton2: () => {
            fetchData(master_type_id);
          },
        });
      }
    } catch (error: any) {
      if (!ModalController.isShowing())
        ModalController.show({
          message: error?.detail,
          visibleButton2: true,
        });
      console.log("error handleChange", error);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  const handleSave = async () => {
    try {
      LoadingOverlayController.show();
      let formData: any[] = data.map((item) => {
        const datilData = {
          PK: item.PK,
          SK: item.SK,
        };
        return datilData;
      });
      const res = await updateCustomMaster(formData);
      if (res) {
        ModalController.show({
          message: messages.COMMON.MSG_COMMON_SUCCESS_001(
            master_name + "の並び順の更新",
          ),
          visibleButton2: true,
          handlePressButton2: () => {
            fetchData(master_type_id);
          },
        });
      }
    } catch (error: any) {
      if (!ModalController.isShowing())
        ModalController.show({
          message: error?.detail,
          visibleButton2: true,
        });
      console.log("error handleSave", error);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  const handleCancel = () => {
    setOpen(false);
    setEditIndex(-1);
  };

  const fetchData = async (
    SK: string,
    isMerge: boolean = false,
    lastKeyword?: any,
  ) => {
    if (!isMerge) {
      LoadingOverlayController.show();
    }
    setLoading(true);

    let last_key = undefined;
    if (isMerge) {
      lastKeyword = lastKeyword ?? lastKey;
      last_key = lastKeyword
        ? JSON.stringify({
            PK: lastKeyword.PK,
            SK: lastKeyword.SK,
            master_extract_key: lastKeyword.master_extract_key,
            seq: lastKeyword.seq,
          })
        : undefined;
    }

    await getListCustomMaster(SK, last_key)
      .then((res) => {
        if (res?.data) {
          if (isMerge) {
            setData(data.concat(res.data));
            setOriginData(originData.concat(res.data));
          } else {
            setData(res.data);
            setOriginData(res.data);
          }

          if (res?.last_key) {
            setLastKey(res?.last_key ?? null);
            setLoadMore(true);
          } else {
            setLastKey(null);
            setLoadMore(false);
          }
        }
      })
      .finally(() => {
        LoadingOverlayController.hide();
        setLoading(false);
      });
  };

  useEffect(() => {
    setDisabled(JSON.stringify(data) === JSON.stringify(originData));
  }, [data, originData]);

  const showLoadMore = useMemo(() => {
    return loadMore && lastKey && data.length > 0;
  }, [data, loadMore, lastKey]);

  const handleLoadMore = () => {
    if (loadMore && lastKey && data.length > 0 && !loading) {
      fetchData(master_type_id, true, lastKey);
    }
  };

  return (
    <GenericTemplate title="ユーザー作成マスタ管理">
      <Typography variant="h6" sx={{ wordBreak: "break-all" }}>
        {master_name}
      </Typography>

      <CustomMasterInfoEditDialog
        data={editData}
        open={open}
        onChange={handleChange}
        onClose={handleCancel}
      />
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1 }}>
        <Typography variant="body2">ドラッグ＆ドロップで並べ替え</Typography>
        <Box sx={{ flexGrow: 1 }} />
        <MasterExportButton
          masterType={master_type_id}
          activityBaseId={activityBaseId}
          userInfo={user_info}
          isDisabled={false}
        />
        <Button onClick={handleAdd} endIcon={<AddCircle />}>
          追加
        </Button>
      </Box>
      <Box>
        <DragDropContext onDragEnd={handleDnd}>
          <Droppable droppableId="droppableId-1">
            {(provided: DroppableProvided) => (
              <List ref={provided.innerRef} {...provided.droppableProps}>
                <InfiniteScroll
                  threshold={10}
                  loadMore={handleLoadMore}
                  hasMore={showLoadMore}
                  loader={<ScrollLoader key={0} />}
                >
                  {data.map((item, i) => (
                    <Draggable key={item.SK} draggableId={item.SK} index={i}>
                      {(provided: DraggableProvided) => (
                        <ListItem
                          sx={{ bgcolor: "white" }}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          secondaryAction={
                            <>
                              <Tooltip title="編集">
                                <IconButton
                                  edge="end"
                                  sx={{ mx: 1 }}
                                  onClick={() => handleEdit(item, i)}
                                >
                                  <Edit />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="削除">
                                <IconButton
                                  edge="end"
                                  onClick={() => handleDelete(item.SK)}
                                >
                                  <Delete />
                                </IconButton>
                              </Tooltip>
                            </>
                          }
                          divider
                        >
                          <span className="IgnoreExtractRuleTarget">
                            <ListItemText
                              primary={item[item_name]}
                              primaryTypographyProps={{
                                style: {
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  marginRight: "40px",
                                  color: item.is_default
                                    ? Colors.REQUIRE
                                    : Colors.TEXT,
                                },
                              }}
                            />
                          </span>
                        </ListItem>
                      )}
                    </Draggable>
                  ))}
                </InfiniteScroll>
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
      <Box sx={{ height: 30 }} />
      <Box
        sx={{
          position: "fixed",
          margin: 0,
          right: 0,
          bottom: 20,
          textAlign: "center",
          width: "100%",
        }}
      >
        <Button onClick={handleSave} color="secondary" disabled={disabled}>
          保存
        </Button>
      </Box>
    </GenericTemplate>
  );
};

export default CustomMasterInfoScreen;
