import { TYPES } from "store/types";
import { removeConditionFilterHelp, updateFilterProject } from "shared/utils";

const initialState: any = {
  project: {
    status: [],
    expired: null,
  },
  folder: {
    category: [],
    author: [],
    format: [],
    documents: [],
  },
  work: {
    work_status: [],
    expired: null,
    category: [],
    author: [],
  },
  work_group: {
    work_status: [],
    expired: null,
    category: [],
    author: [],
  },
  work_delete: {
    work_status: [],
    expired: null,
    category: [],
    author: [],
  },
  work_copy: {
    work_status: [],
    expired: null,
    category: [],
    author: [],
  },
  // <<--- TLOG
  work_status: {
    author: [],
    project: [],
  },
  members_management: {
    location: [],
    role: [],
  },
  handover_report: {
    author: [],
    location: [],
  },
  custom_template: {
    author_all: [],
    location_all: [],
    report: [],
  },
  custom_template_trash: {
    author_all: [],
    location_all: [],
    report: [],
  },
  lock_manage: {
    location_all: [],
  },
  // --->> TLOG
};

export const filterReducer = (state = initialState, { payload, type }: any) => {
  switch (type) {
    case TYPES.SET_FILTER_CONDITION_PROJECT: {
      return updateFilterProject(state, payload);
    }
    case TYPES.REMOVE_FILTER_CONDITION: {
      let { type_screen, ...otherPayload } = payload;
      return removeConditionFilterHelp(state, otherPayload, type_screen);
    }
    case TYPES.CLEAR_FILTER_CONDITION: {
      let { type_screen } = payload;
      return {
        ...state,
        [type_screen]: { ...initialState[type_screen] },
      };
    }
    case TYPES.SET_ALL_FILTER_CONDITION: {
      return { ...payload };
    }
    case TYPES.SET_EACH_FILTER_CONDITION: {
      let { type_screen, data } = payload;
      return { ...state, [type_screen]: { ...data } };
    }
    case TYPES.CLEAR_ALL_FILTER_CONDITION:
      return initialState;
    default:
      return state;
  }
};
