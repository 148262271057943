import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import formatDateToString from "@utils/DateFormat";
import { deleteBannerMessage } from "@api/banner";

interface IProps {
  open: boolean;
  onClose: (deletedIndex: number[]) => void;
  messageData?: any[];
}

const MessagesDialog: React.VFC<IProps> = ({
  open,
  onClose,
  messageData = [],
}: IProps) => {
  const [viewIndex, setViewIndex] = useState(0);
  const [listDeleteIndex, setListDeleteIndex] = useState<number[]>([]);

  const message = useMemo(() => {
    return messageData.length > viewIndex ? messageData[viewIndex] : undefined;
  }, [messageData, viewIndex]);

  useEffect(() => {
    if (open) {
      setViewIndex(0);
      setListDeleteIndex([0]);
    }
  }, [open]);

  useEffect(() => {
    setListDeleteIndex((prev) => {
      prev.push(viewIndex);
      return prev;
    });
  }, [viewIndex]);

  const handleClose = async () => {
    const list_delete = [...new Set(listDeleteIndex)];
    if (messageData.length > 0) {
      // 表示済みメッセージを削除
      const params = list_delete.map((value) => {
        return {
          PK: messageData[value]?.PK,
          SK: messageData[value]?.SK,
        };
      });
      await deleteBannerMessage(params);
    }
    onClose(list_delete);
  };

  return (
    <Dialog
      maxWidth="xs"
      sx={{ "& .MuiDialog-paper": { width: "80%" } }}
      open={open}
      onClose={undefined}
    >
      <DialogContent sx={{ pb: 0, maxHeight: 200 }}>
        {/* メッセージ内容 */}
        {message && (
          <>
            <Box mb={1}>
              <Typography>
                {formatDateToString(message.created_at, "YMDHms_sl")}
              </Typography>
            </Box>
            <Box>
              {message.message
                .split(/<br>|\n/g)
                .map((text: string, i: number) => (
                  <Typography key={i}>{text}</Typography>
                ))}
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", flexDirection: "column" }}>
        {/* ページネーション */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IconButton
            onClick={() => setViewIndex(viewIndex - 1)}
            disabled={viewIndex <= 0}
          >
            <NavigateBefore />
          </IconButton>
          <Typography mx={1}>
            {viewIndex + 1} / {messageData.length}
          </Typography>
          <IconButton
            onClick={() => setViewIndex(viewIndex + 1)}
            disabled={viewIndex + 1 >= messageData.length}
          >
            <NavigateNext />
          </IconButton>
        </Box>
        {/* フッター */}
        <Button variant="outlined" onClick={handleClose}>
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MessagesDialog;
