import { useMemo } from "react";
import { Box, Card } from "@mui/material";
import messages from "config/messages";
import {
    HeadCell,
    TableInfiniteScroll,
} from "components/organisms/TableInfiniteScroll";

interface ILogItem {
    PK: string;
    SK: string;
    export_account_id: string;
    export_account_name: string;
    export_at: string;
    export_count: string;
    export_data_type: string;
    display_export_data_type: string;
}

interface IProps {
    listLog: ILogItem[];
    onChangePage: Function;
    lastKey: string;
    activityBaseId: string;
    loadMore: boolean;
}


const LogList = ({ listLog,
    onChangePage,
    lastKey,
    activityBaseId,
    loadMore
}: IProps) => {
    const headCells: HeadCell[] = [
        {
            id: "export_at",
            label: "出力日時",
            width: 150,
            isIgnoreExtractRuleCell: true,
        },
        {
            id: "display_export_data_type",
            label: "出力マスタ種類",
            width: 150,
            isIgnoreExtractRuleCell: true,
        },
        {
            id: "export_account_name",
            label: "出力アカウント名",
            width: 180,
            isIgnoreExtractRuleCell: true,
        },
        {
            id: "export_count",
            label: "出力データ件数",
            width: 180,
            isIgnoreExtractRuleCell: true,
        },
    ];
    const showLoadMore = useMemo(() => {
        return Boolean(lastKey !== "" && listLog.length > 0 && loadMore);
    }, [listLog, lastKey, loadMore]);

    return (
        <Card style={{ minHeight: 500 }}>
            <Box sx={{ m: 2, overflow: "auto" }}>
                <TableInfiniteScroll
                    rows={listLog}
                    headCells={headCells}
                    idName="SK"
                    maxHeight={"60vh"}
                    showTooltip
                    noWrap
                    initialLoad={false}
                    hasMore={showLoadMore}
                    loadMore={() => {
                        if (showLoadMore) {
                            setTimeout(() => {
                                onChangePage(activityBaseId, lastKey, true);
                            }, 300);
                        }
                    }}
                    message={
                        listLog.length === 0
                            ? messages.COMMON.MSG_NOT_EXIST("ログ")
                            : ""
                    }
                />
            </Box>
        </Card>
    );
};

export default LogList;