import React, { useCallback, useEffect, useState } from "react";
import { Button, Card, CardActions, CardContent, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import GenericTemplate from "@template/index";
import { ActivityBase } from "services/models";
import { getListActivityBase } from "@api/groupMaster";
import { deleteLock, getListLock } from "@api/lock";
import messages from "config/messages";
import {
  HeadCell,
  SortableTable,
} from "components/organisms/SortableTable/SortableTable";
import FilterInput from "components/molecules/FilterInput";
import { getConditionFilter } from "selector/filterSelector";
import { getParamsFilter, getUserInfo } from "@utils/index";
import { RootState } from "store/reducer";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import ModalController from "@shared-components/modal/ModalController";
import { TYPES } from "store/types";
import formatDateToString from "@utils/DateFormat";
import useDeepCompareEffect from "shared/hook/useDeepCompareEffect";

let paramsFilter: any;

const LockListScreen: React.VFC = () => {
  const [allListLock, setAllListLock] = useState<any[]>([]);
  const [listLock, setListLock] = useState<any[]>([]);
  const dispatch = useDispatch();
  const user_info = getUserInfo();
  const TYPE_SCREEN = "lock_manage";
  const { check_list } = useSelector((state: RootState) => state.tableCustom);
  const lockFilter = useSelector(getConditionFilter(TYPE_SCREEN));
  const headCells: HeadCell[] = [
    {
      id: "category",
      label: "カテゴリー",
      width: 100,
      sortable: true,
    },
    {
      id: "data_content",
      label: "内容",
      width: 100,
      sortable: true,
    },
    {
      id: "activity_base_name",
      label: "拠点",
      width: 100,
      sortable: true,
    },
    {
      id: "created_at",
      label: "制御日時",
      width: 100,
      sortable: true,
    },
    {
      id: "created_by_fullname",
      label: "制御アカウント",
      width: 200,
      sortable: true,
    },
  ];

  // ------------------------------------------------------------------
  // 画面描画
  // ------------------------------------------------------------------
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      LoadingOverlayController.show();
      let listLocation: any[] = [];
      const reslocation = await getListActivityBase();
      if (reslocation) {
        listLocation = reslocation;
      }
      getListLock().then((res) => {
        if (res?.data) {
          const newListLock = res.data.map((item: any) => ({
            ...item,
            activity_base_name: getActivityBaseName(
              listLocation,
              item.activity_base_id,
            ),
            created_at: formatDateToString(item.created_at, "YMDHms_sl"),
          }));
          setListLock(newListLock);
          setAllListLock(newListLock);
          filterListLock(newListLock);
        }
      });
      dispatch({ type: TYPES.SET_CHECK_TABLE, check_list: [] });
    } finally {
      LoadingOverlayController.hide();
    }
  };

  const getActivityBaseName = useCallback(
    (listLocation: ActivityBase[], activity_base_id: string) => {
      const location = listLocation.find(
        (item) => item.SK === activity_base_id,
      );
      return location ? location.activity_base_name : "";
    },
    [],
  );

  // ------------------------------------------------------------------
  // 解除
  // ------------------------------------------------------------------
  const handleDelete = () => {
    if (!ModalController.isShowing()) {
      ModalController.show({
        message: messages.COMMON.MSG_COMMON_DELETE_CONFIRM_001,
        visibleButton1: true,
        visibleButton2: true,
        handlePressButton2: () => handledeleteLock(),
      });
    }
    return;
  };

  const handledeleteLock = async () => {
    try {
      LoadingOverlayController.show();
      const params = check_list.map((value) => ({
        contract_id: user_info.contract_id,
        SK: value,
      }));
      const res = await deleteLock(params);
      if (res) {
        ModalController.show({
          message:
            messages.COMMON.MSG_COMMON_DELETE_SUCCESS_001("排他制御データ"),
          visibleButton2: true,
          handlePressButton2: () => {
            dispatch({ type: TYPES.SET_CHECK_TABLE, check_list: [] });
            setAllListLock(res.data);
            filterListLock(res.data);
          },
        });
      }
    } catch (error: any) {
      if (!ModalController.isShowing())
        ModalController.show({
          message: error?.detail,
          visibleButton2: true,
        });
      console.log("error handleUpdateAccount", error);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  // ------------------------------------------------------------------
  // 絞り込み
  // ------------------------------------------------------------------
  useDeepCompareEffect(() => {
    paramsFilter = getParamsFilter(lockFilter);
  }, [lockFilter]);

  const filterListLock = (data = allListLock) => {
    if (paramsFilter.length === 0 || paramsFilter.split("/").length < 2) {
      // 全件表示
      setListLock(data);
    } else {
      // 絞り込むactivity_base_idの配列
      const filter_list = paramsFilter.split("/")[1].split(",");
      const tmpListLock = data.filter((item) =>
        filter_list.includes(item.activity_base_id),
      );
      setListLock(tmpListLock);
    }
  };

  return (
    <GenericTemplate title="排他制御管理">
      <Grid container spacing={2} mb={2}>
        <Grid
          item
          xs={12}
          md="auto"
          sx={{ width: { md: "40%" }, marginLeft: "auto" }}
        >
          {/* =================== 絞り込み =================== */}
          <FilterInput
            typeScreen={TYPE_SCREEN}
            disabled={allListLock.length <= 0}
            onFetchData={() => {
              LoadingOverlayController.show();
              filterListLock();
              LoadingOverlayController.hide();
            }}
          />
        </Grid>
      </Grid>
      <Card>
        <CardContent>
          <SortableTable
            rows={listLock}
            headCells={headCells}
            idName="SK"
            order="asc"
            orderByIdName="created_at"
            isCheckRow
          />
        </CardContent>
        <CardActions sx={{ justifyContent: "center" }}>
          <Button color="secondary" disabled={!listLock.length} onClick={handleDelete}>
            解除
          </Button>
        </CardActions>
      </Card>
    </GenericTemplate>
  );
};

export default LockListScreen;
