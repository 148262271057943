import React, { useEffect, useState, useMemo, VFC, useRef } from "react";
import SnackbarController from "@shared-components/snackBar/SnackbarController";
import GenericTemplate from "@template/index";
import { createFormUpload } from "@utils/index";
import formatDateToString from "@utils/DateFormat";
import {
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    ListSubheader,
    MenuItem,
    Stack,
} from "@mui/material";
import _ from "lodash";
import messages from "config/messages";
import ModalController from "@shared-components/modal/ModalController";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import { getUserInfo } from "@utils/index";
import { downloadXlsx } from "@utils/CSV";
import SelectLabel from "components/atoms/SelectLabel";
import { exportMaster } from "@api/master";
import { getListLogExportApi, insertLogApi } from "@api/log";
import { getMasterType } from "@api/masterType";
import { Colors } from "@template/style";
import {
    IMasterList,
    LIST_EXPORT_CSV,
    LIST_GROUP,
    LIST_EXPORT_MASTER,
    LIST_EXPORT_ACCOUNT,
    LIST_ACCOUNT
} from "shared/constants/MasterInfo";
import {
    ExportMasterParamater
} from "services/models";
import LogList from "./logList";

interface IStateForm {
    master: string;
    master_name: string;
    input_master_name: string;
    keyitem: boolean;
}

const initialStateForm: IStateForm = {
    master: "",
    master_name: "",
    input_master_name: "",
    keyitem: false,
};

interface ILogItem {
    PK: string;
    SK: string;
    export_account_id: string;
    export_account_name: string;
    export_at: string;
    export_count: string;
    export_data_type: string;
    display_export_data_type: string;
}

export type ISelectedData = { [key: string]: string };

export interface IGroupEditData {
    PK: string;
    SK: string;
    group_parentid?: string;
    groupName: string;
    isCreateNew?: boolean;
    index: number;
    groupNameAll?: string;
    selectedData?: ISelectedData;
}

const MasterExportScreen: VFC = () => {
    // ------------------------------------------------------------------
    // 初期化
    // ------------------------------------------------------------------
    const [listMaster, setListMaster] = useState<IMasterList[]>([]);
    const [stateForm, setStateForm] = useState<IStateForm>({
        ...initialStateForm,
    });
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [isDisable, setIsDisable] = useState<boolean>(true);
    const [activityBaseId, setActivityBaseId] = useState<string>("");
    const [listMasterType, setListMasterType] = useState<Array<any>>([]);
    const [listLog, setListLog] = useState<Array<ILogItem>>([]);
    const [lastKey, setLastKey] = useState<string>("");
    const [loadMore, setLoadMore] = useState<boolean>(false);
    const accountCreate = LIST_ACCOUNT.filter((value) => (value.id === "ACCOUNT_CREATE"));
    const user_info = getUserInfo();
    const prevArgs = useRef<{ activityBaseId: string; lastKey: string } | null>(null);
    useEffect(() => {
        const fetchData = async () => {
            setActivityBaseId(user_info.location_id);
            const masterList = await getMasterTypeList(user_info.location_id);
            await getData(user_info.location_id, lastKey, false, masterList);
        };
        fetchData();
    }, []);

    useEffect(() => {
        setIsLoading(Boolean(!listMaster || listMaster.length === 0))
    }, [listMaster]);

    const getMasterTypeList = async (activityBaseId: string): Promise<any[]> => {
        var list_account = [...LIST_EXPORT_ACCOUNT];
        if (!activityBaseId) {
            setListMaster(list_account);
            return list_account; // リストを返す
        } else {
            LoadingOverlayController.show();
            return await getMasterType(activityBaseId, true)
                .then((res) => {
                    var new_list_master = [...LIST_EXPORT_MASTER, ...list_account];
                    if (res?.data) {
                        const list_user_master = res.data.map((v: any) => ({
                            id: v.SK,
                            name: v.name,
                            group: LIST_GROUP.USER_MASTER.name,
                            custom_item: res.data.custom_item
                        }));
                        new_list_master = new_list_master.concat(list_user_master);
                        setListMasterType(res.data);
                    }
                    setListMaster(new_list_master);
                    return new_list_master; // リストを返す
                })
                .finally(() => LoadingOverlayController.hide());
        }
    };

    const getData = async (
        activityBaseId: string,
        lastKey: string,
        isMerge: boolean,
        masterList?: any[]
    ) => {
        // 引数が変わらなければ何もしない
        if (
            prevArgs.current &&
            prevArgs.current.activityBaseId === activityBaseId &&
            prevArgs.current.lastKey === lastKey
        ) {
            return;
        }

        // 引数を更新
        prevArgs.current = { activityBaseId, lastKey };
        if (isFetching) return;
        setIsFetching(true);
        getListLog(activityBaseId,
            lastKey,
            isMerge,
            masterList);

    }

    const getListLog = async (activityBaseId: string,
        lastKey: string,
        isMerge: boolean,
        masterList?: any[]
    ) => {
        LoadingOverlayController.show();
        const currentMasterList = listMaster.length ? listMaster : masterList ?? [];
        await getListLogExportApi(lastKey, activityBaseId)
            .then((res) => {
                if (res?.data) {
                    setListLog((prevList) => {
                        let newListLog = isMerge ? [...prevList, ...res.data] : [...res.data];
                        newListLog = newListLog.map((item) => ({
                            ...item,
                            display_export_data_type:
                                currentMasterList.find((d) => d.id === item?.export_data_type)?.name || "",
                            export_at: formatDateToString(item?.export_at, "YMDHms_sl"),
                        }));

                        return newListLog;
                    });
                }
                if (res?.last_key) {
                    setLoadMore(true);
                    setLastKey(res.last_key);
                } else {
                    setLoadMore(false);
                }
                // 10件まで取得 (表示件数と合わせる)
                if (res?.data.length < 10 && res.last_key !== "") {
                    getListLog(activityBaseId, res.last_key, true, currentMasterList);
                }
            })
            .finally(() => {
                setIsFetching(false);
                LoadingOverlayController.hide();
            });
    }


    const handleClear = () => {
        //初期化
        setListLog([]);
        setLastKey("");
    };

    // ------------------------------------------------------------------
    // CSVダウンロード
    // ------------------------------------------------------------------
    const confirmDownload = async () => {
        if (!ModalController.isShowing()) {
            ModalController.show({
                message: messages.MASTER.MSG_CONFIRM_MASTER_DOWNLOAD,
                visibleButton1: true,
                visibleButton2: true,
                handlePressButton2: () => {
                    handleDownload();
                },
            });
        }
    }
    const handleDownload = async () => {
        LoadingOverlayController.show();
        const formData: ExportMasterParamater = {
            master: stateForm.master,
            master_name: stateForm.input_master_name,
            activity_base_id: activityBaseId,
        };

        try {
            const res = await exportMaster(formData);
            let columns: any = [];
            if (stateForm.master === "ACCOUNT") {
                const id = accountCreate[0]?.id;
                columns = LIST_EXPORT_CSV[id] || [];
            } else if (stateForm.master.includes("MASTER_TYPE")) {
                // ユーザー作成マスタの場合は、マスタデータから任意項目を追加
                columns = [...LIST_EXPORT_CSV[LIST_GROUP.USER_MASTER.id]];
                const master_type = listMasterType.find(
                    (v) => v.SK == stateForm.master,
                );
                if (master_type && master_type.custom_item) {
                    master_type.custom_item.forEach((v: any, i: number) => {
                        columns.push({ header: v.name, key: "item" + String(i + 1) });
                    });
                }
            }
            else {
                columns = LIST_EXPORT_CSV[stateForm.master] || [];
            }

            if (res && "data_length" in res && "is_exceeded" in res) {
                if (res.is_exceeded) {
                    //成功ダイアログ
                    ModalController.show({
                        message: messages.MASTER.MSG_DOWNLOAD_COUNT_EXCEEDED(res.data_length),
                        visibleButton2: true,
                    });
                } else {
                    SnackbarController.show({
                        severity: "success",
                        message: messages.MASTER.MSG_DOWNLOAD_COUNT(res.data_length),
                    });
                }
                await downloadXlsx({
                    data: res.data,
                    columns: columns,
                    filename: `${stateForm.master_name || "Untitled"}マスタ.csv`,
                });
                insertLog(res.data_length)
            } else {
                throw new Error();
            }
        } catch (error) {
            console.log(error);
            const errorMessage = messages.COMMON.MSG_COMMON_ERROR_001;
            ModalController.show({
                message: errorMessage,
                visibleButton2: true,
            });
        } finally {
            LoadingOverlayController.hide()
        }
    };

    // ------------------------------------------------------------------
    // ログの書き込み
    // ------------------------------------------------------------------
    const insertLog = async (count: number) => {
        const data = {
            export_account_id: user_info.SK,
            export_account_name: user_info.full_name,
            export_count: String(count),
            export_data_type: stateForm.master,
            import_data_type: "",
            import_account_id: "",
            import_account_name: "",
            import_count: 0,
            is_export: true, // true→export, false→import
            activity_base_id: activityBaseId
        }
        const formData = createFormUpload(null, data);
        let res = await insertLogApi(formData);
        if (res) {
            handleClear();
            getData(user_info.location_id, "", false);
        }
    }

    const renderOptionListTemplate = useMemo(() => {
        return listMaster.map((item, index) => {
            var subheader = false;
            var response = [];
            if (index == 0 || listMaster[index - 1].group != item.group) {
                subheader = true;
                response.push(
                    <ListSubheader
                        sx={{ color: Colors.LIGHT_GRAY, fontStyle: "italic" }}
                        key={index}
                    >
                        {item.group}
                    </ListSubheader>,
                );
            }
            response.push(
                <MenuItem
                    value={item.id}
                    key={subheader ? undefined : index}
                >
                    {item.name}
                </MenuItem>,
            );
            return response;
        })
    }, [listMaster]);

    return (
        <GenericTemplate title="マスタ出力ログ一覧">
            {/*
            <Card>
                <CardContent>
                    <Stack >
                        <Box
                            display={"flex"}
                            flexDirection={"row"}
                            alignItems="center" // 中央揃え
                            gap={2} // 要素間の隙間を調整
                        >
                            <Box
                                display={"flex"}
                                flexDirection={"column"}
                                sx={{ width: { xs: "100%", md: "50%" } }}
                            >
                                <SelectLabel
                                    label={"マスタ種類"}
                                    fullWidth
                                    value={stateForm.master}
                                    onChange={(e) => {
                                        const item = listMaster.find((v) => v.id === e.target.value);
                                        if (item) {
                                            setStateForm({
                                                ...stateForm,
                                                master: item.id,
                                                master_name: item.name,
                                                input_master_name: "",
                                                keyitem: false,
                                            });
                                            setIsDisable(false)
                                        }
                                    }}
                                    MenuProps={{ style: { maxHeight: 300 } }}
                                >
                                    {isLoading ? (
                                        <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                                            <CircularProgress size={24} />
                                        </Box>
                                    ) : (
                                        renderOptionListTemplate
                                    )}
                                </SelectLabel>
                            </Box>

                            <Box sx={{ marginTop: 3, width: { xs: "100%", md: "50%" } }}>
                                <Button
                                    disabled={isDisable}
                                    color="secondary"
                                    onClick={() => {
                                        confirmDownload()
                                    }}
                                >
                                    ダウンロード
                                </Button>
                            </Box>
                        </Box>
                    </Stack>
                </CardContent>
            </Card>
            */}
            {/* <div style={{ marginBottom: "16px" }} /> */}
            <LogList
                listLog={listLog}
                onChangePage={getData}
                lastKey={lastKey}
                activityBaseId={activityBaseId}
                loadMore={loadMore && !isFetching}
            />

        </GenericTemplate >

    );
};

export default MasterExportScreen;


